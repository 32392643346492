import { useEffect } from "react"

const useSmoothScroll = () => {
  useEffect(() => {
    const anchorLinks = document.querySelectorAll('a[href^="#"]')

    anchorLinks.forEach((link) => {
      link.addEventListener("click", (e: any) => {
        e.preventDefault()

        const target = document.querySelector(
          e.currentTarget.getAttribute("href"),
        )

        if (target !== undefined) {
          target.scrollIntoView({
            behavior: "smooth",
          })
        }
      })
    })
  }, [])
}

export default useSmoothScroll
