import React from "react"

import Button from "../../common/buttons/Button"

interface ModalProps {
  onSubmit: any
}

const ModalSicam: React.FC<ModalProps> = ({ onSubmit }) => {
  const onMiddleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget as HTMLFormElement)
    const file = formData.get("sicam") as File
    onSubmit(file)
  }
  return (
    <form action="#" onSubmit={onMiddleSubmit}>
      <div className="mb-4">
        <h6 className="block mb-2 text-sm font-medium text-gray-900">
          Ingrese archivo SICAM en formato html
        </h6>
        <input
          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
          id={"sicam"}
          name={"sicam"}
          accept=".html"
          type={"file"}
          required
          placeholder={"SICAM"}
        />
      </div>
      <div className="flex items-center justify-end space-x-4">
        <Button color={"primary"} size={"sm"} outline={false}>
          {"Importar archivo"}
        </Button>
      </div>
    </form>
  )
}

export default ModalSicam
