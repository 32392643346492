// import { initMercadoPago } from "@mercadopago/sdk-react"
import { FC, useContext } from "react"
import { RouterProvider } from "react-router-dom"

import { ToastContext } from "./providers/Toasts/ToastContext"
import router from "./routes/router"

// const publicKey = import.meta.env.VITE_MP_PUBLIC_KEY as string

const App: FC = () => {
  // initMercadoPago(publicKey, {
  //   locale: "es-AR",
  // })
  const { toasts } = useContext(ToastContext)
  return (
    <>
      <RouterProvider router={router} />
      {toasts}
    </>
  )
}

export default App
