import PropTypes from "prop-types"

import { getTipoCalculoVars } from "../DeterminationForm/DeterminationUtils"

interface DeterminationResultProps {
  tipoCalculo: number
}

const DeterminationResult: React.FC<DeterminationResultProps> = ({
  tipoCalculo,
}) => {
  const { isDet, isInvalidPension } = getTipoCalculoVars(tipoCalculo)
  const renderTitle = (
    <>
      cálculo de{" "}
      {isDet
        ? "determinación de derecho"
        : isInvalidPension
        ? "pensión por invalidez"
        : "pensión por fallecimiento"}
    </>
  )

  return (
    <div className="mb-10 max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        Excelente!
      </h5>
      <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
        Tu {renderTitle} ha sido completado y te hemos generado un archivo.
        <br />
        Haz click para descargarlo o vuelve hacia atrás si deseas modificar el
        cálculo.
      </p>
    </div>
  )
}

DeterminationResult.propTypes = {
  tipoCalculo: PropTypes.number.isRequired,
}

export default DeterminationResult
