const Paths = {
  home: "/",
  policy: "/privacidad",
  terms: "/terminos-condiciones",
  subscription: "/subscripcion",
  checkout: "/checkout",
  register: "/empeza",
  login: "/login",
  forgot: "/recuperar",
  reset: "/restablecer",
  login_successful: "/login_successful",
  account: "/mi-cuenta",
  determination: "/determinacion",
  invalid_pension: "/determinacion-invalidez",
  dead_pension: "/determinacion-fallecimiento",
}

export default Paths
