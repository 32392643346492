// import {
//   faFacebook,
//   faInstagram,
//   faTwitter,
// } from "@fortawesome/free-brands-svg-icons"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Link } from "react-router-dom"

import Paths from "../routes/paths"
import logoContrast from "./../assets/images/logo-contrast.png"

const Footer: React.FC = () => {
  const isRootPath = Paths.home === location.pathname
  return (
    <div
      className={`w-full ${isRootPath ? "h-full" : "fixed h-[70px] bottom-0"}`}
    >
      <footer className="w-full h-full flex justify-between items-center bg-ct-trademark-dark text-white p-4">
        <div className="flex items-center">
          <a href={Paths.home}>
            <img src={logoContrast} alt="Logo" className="w-auto h-8 mr-2" />
          </a>
          <span className="hidden sm:block mr-3">
            {" "}
            © {new Date().getFullYear()}
          </span>
          <div className="flex items-center space-x-4 ml-5 mr-5">
            {/* <a
            href="https://twitter.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-ct-blue-600 hover:text-ct-blue-700"
          >
            <FontAwesomeIcon icon={faTwitter as any} size="lg" />
          </a>
          <a
            href="https://instagram.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-red-400 hover:text-red-400"
          >
            <FontAwesomeIcon icon={faInstagram as any} size="lg" />
          </a>
          <a
            href="https://facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 hover:text-blue-400"
          >
            <FontAwesomeIcon icon={faFacebook as any} size="lg" />
          </a> */}
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <Link className="hover:underline" to={Paths.terms}>
            Términos y condiciones
          </Link>
          <Link className="hover:underline" to={Paths.policy}>
            Política de privacidad
          </Link>
        </div>
      </footer>
    </div>
  )
}

export default Footer
