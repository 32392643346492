/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { FormEvent, MutableRefObject, useEffect } from "react"

interface useOutboundClickProps {
  elementRef: MutableRefObject<HTMLElement | null>
  onOuterClickSubcription: () => void
  onInnerClickSubcription: () => void
}

const useOutboundClick = ({
  elementRef,
  onOuterClickSubcription,
  onInnerClickSubcription,
}: useOutboundClickProps) => {
  const handleClickOutside = (event: FormEvent) => {
    if (
      elementRef?.current &&
      !elementRef?.current?.contains(event.target as Node)
    ) {
      onOuterClickSubcription()
    } else {
      onInnerClickSubcription()
    }
  }

  useEffect(() => {
    document.body.addEventListener("click", handleClickOutside as any)
    return () => {
      document.body.removeEventListener("click", handleClickOutside as any)
    }
  }, [])
}

export default useOutboundClick
