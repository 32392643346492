import {
  faBell,
  faCalculator,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"

import logo from "../../../../app/assets/images/page-icon.png"
import Popover from "../../../components/common/popover/Popover"
import CheckIcon from "../../../components/common/svg/CheckIcon"
import MinusIcon from "../../../components/common/svg/MinusIcon"
import { RightArrow } from "../../../components/common/svg/RightArrowIcon"
import Paths from "../../../routes/paths"

const PricingDesktop = () => {
  const [hoveredColumn, setHoveredColumn] = useState<number | null>(2)

  const Cell = ({
    columnNumber,
    className,
    children,
  }: {
    columnNumber: number
    className?: string
    children: any
  }) => (
    <td
      className={`px-4 py-4 text-center ${className as string} ${
        hoveredColumn !== columnNumber
          ? "border-b border-gray-200"
          : "text-white bg-gray-900 border-b border-white/20"
      }`}
      onMouseEnter={() => setHoveredColumn(columnNumber)}
      onMouseLeave={() => setHoveredColumn(null)}
    >
      {children}
    </td>
  )

  const HeaderCell = ({
    columnNumber,
    children,
  }: {
    columnNumber: number
    children: any
  }) => (
    <th
      className={`lg:table-cell px-4 py-8 text-center min-w-[200px] max-w-[230px] ${
        hoveredColumn !== columnNumber
          ? ""
          : "text-white bg-gray-900 rounded-t-xl"
      }`}
      onMouseEnter={() => setHoveredColumn(columnNumber)}
      onMouseLeave={() => setHoveredColumn(null)}
    >
      {children}
    </th>
  )

  return (
    <table className="w-full">
      <thead>
        <tr>
          <th className="py-8 pr-4"></th>

          <HeaderCell columnNumber={0}>
            <div className="min-h-[240px]">
              <span className="px-4 py-2 text-base font-medium text-white bg-blue-600 rounded-full">
                {" "}
                Prueba{" "}
              </span>
              <p className="mt-6 text-6xl lg:text-5xl font-bold">$0</p>
              <p className="mt-2 font-normal text-gray-500">
                <span className="text-base">Prueba gratuita</span>
              </p>
              <p className="mt-6 text-6xl font-bold"></p>
            </div>
          </HeaderCell>
          <HeaderCell columnNumber={1}>
            <div className="min-h-[240px]">
              <span className="px-4 py-2 text-base font-medium text-white bg-blue-600 rounded-full">
                {" "}
                Estudio{" "}
              </span>
              <p className="mt-6 text-6xl lg:text-5xl font-bold">$9.999</p>
              <p className="mt-2 font-normal text-gray-500">
                <span className="text-base">Por mes</span>
                <span className="text-2xl">*</span>
              </p>
              <p className="mt-4 px-4 py-2 text-small font-medium bg-blue-500 rounded-full">
                <span className="text-white text-base">
                  Prueba gratuita por 10 días
                </span>
              </p>
              <p className="mt-2 font-normal text-gray-500">
                <span className="text-base">
                  Ideal para pequeños estudios jurídicos
                </span>
              </p>
            </div>
          </HeaderCell>
          <HeaderCell columnNumber={2}>
            <div className="min-h-[240px]">
              <span className="px-4 py-2 text-base font-medium text-white bg-blue-600 rounded-full">
                {" "}
                Empresa{" "}
              </span>
              <p className="mt-6 text-6xl lg:text-5xl font-bold">$24.999</p>
              <p className="mt-2 font-normal text-gray-500">
                <span className="text-base">Por mes</span>
                <span className="text-2xl">*</span>
              </p>
              <p className="mt-4 px-4 py-2 text-small font-medium bg-blue-500 rounded-full">
                <span className="text-white text-base">
                  Prueba gratuita por 10 días
                </span>
              </p>
              <p className="mt-2 font-normal text-gray-500">
                <span className="text-base">Ideal para grupos asociados</span>
              </p>
            </div>
          </HeaderCell>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td className="py-4 pr-4 font-medium border-b border-gray-200">
            <FontAwesomeIcon icon={faUser} className="mr-3 w-5" />
            Creación y mantenimiento de cuenta
          </td>
          <Cell columnNumber={0}>Gratis</Cell>
          <Cell columnNumber={1}>Gratis</Cell>
          <Cell columnNumber={2}>Gratis</Cell>
        </tr>

        <tr>
          <td className="py-4 pr-4 font-medium border-b border-gray-200">
            <FontAwesomeIcon icon={faCalculator} className="mr-3 w-5" />
            Cálculo de determinación
          </td>
          <Cell columnNumber={0}>5 cálculos / día</Cell>
          <Cell columnNumber={1}>100 cálculos / día</Cell>
          <Cell columnNumber={2}>Ilimitados</Cell>
        </tr>

        <tr>
          <td className="py-4 pr-4 font-medium border-b border-gray-200">
            <FontAwesomeIcon icon={faCalculator} className="mr-3 w-5" />
            Cálculo de haber
          </td>
          <Cell columnNumber={0}>
            <MinusIcon />
          </Cell>
          <Cell columnNumber={1}>20 cálculos / día</Cell>
          <Cell columnNumber={2}>Ilimitados</Cell>
        </tr>

        <tr>
          <td className="py-4 pr-4 font-medium border-b border-gray-200">
            <FontAwesomeIcon icon={faPhone} className="mr-3 w-5" />
            Soporte técnico
          </td>

          <Cell columnNumber={0}>
            <MinusIcon />
          </Cell>
          <Cell columnNumber={1}>
            <CheckIcon />
          </Cell>
          <Cell columnNumber={2}>
            <CheckIcon />
          </Cell>
        </tr>

        <tr>
          <td className="py-4 pr-4 font-medium border-b border-gray-200">
            <FontAwesomeIcon icon={faBell} className="mr-3 w-5" />
            Notificaciones personales
            <Popover
              popoverContent={"Añadiremos esta funcionalidad pronto"}
              direction="right"
              buttonRender={
                <span className="ml-3 px-2 py-1 text-xs font-bold text-white bg-orange-400 rounded-full">
                  Pronto{" "}
                </span>
              }
            />
          </td>
          <Cell columnNumber={0}>
            <MinusIcon />
          </Cell>
          <Cell columnNumber={1}>Hasta 5 expendientes</Cell>
          <Cell columnNumber={2}>Ilimitadas</Cell>
        </tr>

        <tr>
          <td className="py-4 pr-4 font-medium border-b border-gray-200">
            <img src={logo} alt="logo" className="mr-3 w-5 inline-block" />
            A.I. Exp Notify
            <Popover
              popoverContent={"Añadiremos esta funcionalidad pronto"}
              direction="right"
              buttonRender={
                <span className="ml-3 px-2 py-1 text-xs font-bold text-white bg-orange-400 rounded-full">
                  Pronto{" "}
                </span>
              }
            />
          </td>
          <Cell columnNumber={0}>
            <MinusIcon />
          </Cell>
          <Cell columnNumber={1}>
            <MinusIcon />
          </Cell>
          <Cell columnNumber={2}>
            <CheckIcon />
          </Cell>
        </tr>

        <tr>
          <td className="py-6 pr-4"></td>

          <Cell columnNumber={0} className="rounded-b-xl">
            <a
              href={Paths.subscription + "?quota=free"}
              title=""
              className="inline-flex items-center font-semibold text-blue-600 hover:text-blue-700"
            >
              Comenzar
              <RightArrow />
            </a>
          </Cell>
          <Cell columnNumber={1} className="rounded-b-xl">
            <a
              href={Paths.subscription + "?quota=estudio"}
              title=""
              className="inline-flex items-center font-semibold text-blue-600 hover:text-blue-700"
            >
              Comenzar
              <RightArrow />
            </a>
          </Cell>
          <Cell columnNumber={2} className="rounded-b-xl">
            {" "}
            <a
              href={Paths.subscription + "?quota=empresa"}
              title=""
              className="inline-flex items-center font-semibold text-blue-600 hover:text-blue-700"
            >
              Comenzar
              <RightArrow />
            </a>
          </Cell>
        </tr>
      </tbody>
    </table>
  )
}

export default PricingDesktop
