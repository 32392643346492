import "./main.css"

import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"

import App from "./app/App"
import { ModalProvider } from "./app/providers/Modals/ModalProvider"
import { ToastProvider } from "./app/providers/Toasts/ToastProvider"
import { store } from "./app/redux/config/store"
import reportWebVitals from "./reportWebVitals"

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastProvider>
        <ModalProvider>
          <App />
        </ModalProvider>
      </ToastProvider>
    </Provider>
  </React.StrictMode>,
)

reportWebVitals()
