import React, { useEffect, useState } from "react"

const PasswordValidation = ({ password }: { password: string }) => {
  const [validRules, setValidRules] = useState({
    lowerCase: false,
    upperCase: false,
    oneNumber: false,
    charactersCount: false,
  })

  const validatePassword = (password: string) => {
    setValidRules({
      lowerCase: /[a-z]/.test(password),
      upperCase: /[A-Z]/.test(password),
      oneNumber: /\d/.test(password),
      charactersCount: password.length >= 8,
    })
  }

  useEffect(() => {
    validatePassword(password)
  }, [password])

  return (
    <div className="password-validation grid grid-cols-1 sm:grid-cols-2 gap-1 text-sm text-black/60 my-6">
      <div className="flex items-center password-lowercase">
        <div
          className={`circle mr-2 w-2.5 h-2.5 rounded-full ${
            validRules.lowerCase ? "bg-green-700" : "bg-gray-300"
          }`}
        ></div>
        <span>Una minúscula</span>
      </div>
      <div className="flex items-center password-uppercase">
        <div
          className={`circle mr-2 w-2.5 h-2.5 rounded-full ${
            validRules.upperCase ? "bg-green-700" : "bg-gray-300"
          }`}
        ></div>
        <span>Una mayúscula</span>
      </div>
      <div className="flex items-center password-number">
        <div
          className={`circle mr-2 w-2.5 h-2.5 rounded-full ${
            validRules.oneNumber ? "bg-green-700" : "bg-gray-300"
          }`}
        ></div>
        <span>Un número</span>
      </div>
      <div className="flex items-center password-length">
        <div
          className={`circle mr-2 w-2.5 h-2.5 rounded-full ${
            validRules.charactersCount ? "bg-green-700" : "bg-gray-300"
          }`}
        ></div>
        <span>8 caracteres o más</span>
      </div>
    </div>
  )
}

export default PasswordValidation
