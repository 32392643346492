export const isDet = (tipoCalculo: number) => tipoCalculo === 1
export const isInvalidPension = (tipoCalculo: number) => tipoCalculo === 2
export const isDeadPension = (tipoCalculo: number) => tipoCalculo === 3

export const getTipoCalculoVars = (tipoCalculo: number) => {
  return {
    isDet: isDet(tipoCalculo),
    isInvalidPension: isInvalidPension(tipoCalculo),
    isDeadPension: isDeadPension(tipoCalculo),
  }
}
