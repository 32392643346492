import React from "react"
import { useNavigate } from "react-router-dom"

import MercadoPagoCheckout from "../components/Payment/MP/MercadoPagoCheckout"
import useAppLoader from "../hooks/useAppLoader"
import Paths from "../routes/paths"

const SubscriptionPage = () => {
  const { queryValue } = useAppLoader({ param: "quota" })
  const navigate = useNavigate()
  const selected = queryValue === "estudio" ? 1 : queryValue === "free" ? 0 : 2
  if (selected === 0) navigate(Paths.determination)
  return <MercadoPagoCheckout selected={selected} />
}

export default SubscriptionPage
