import { useState } from "react"

function useStepper({ maxValue }: { maxValue: number }) {
  const [currentStep, setCurrentStep] = useState(0)

  const increaseStep = () =>
    currentStep + 1 > maxValue ? "" : setCurrentStep(currentStep + 1)

  const decreaseStep = () =>
    currentStep <= 0 ? "" : setCurrentStep(currentStep - 1)

  return { currentStep, increaseStep, decreaseStep }
}

export default useStepper
