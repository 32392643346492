import { createPopper } from "@popperjs/core"
import React, { ReactNode, useEffect, useRef, useState } from "react"

interface Props {
  buttonRender: ReactNode
  popoverTitle?: string
  popoverContent?: string
  onClick?: Function
  direction?: "top" | "bottom" | "left" | "right"
  className?: string
}

const Popover: React.FC<Props> = ({
  buttonRender,
  popoverTitle,
  popoverContent,
  onClick,
  direction = "right",
  className,
}) => {
  const [clicked, setClicked] = useState(false)
  const buttonRef = useRef(null)
  const popoverRef = useRef(null)

  useEffect(() => {
    if (buttonRef.current !== null && popoverRef.current !== null) {
      createPopper(buttonRef.current, popoverRef.current, {
        placement: direction,
      })
    }
  }, [direction, popoverRef.current, buttonRef.current])

  const onButtonClick = () => {
    setClicked(!clicked)
    onClick?.()
  }
  const forceClose = () => setClicked(false)
  const forceOpen = () => setClicked(true)

  const popoverClass = `${
    className as string
  } visible absolute z-10 inline-block max-w-[250px] w-full text-sm transition-opacity duration-500
  rounded-lg shadow-sm opacity-1 text-gray-400 border border-gray-600 bg-gray-800`

  return (
    <>
      <span
        role="button"
        tabIndex={0}
        ref={buttonRef}
        className="cursor-pointer"
        onClick={onButtonClick}
        onMouseEnter={forceOpen}
        onMouseLeave={forceClose}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === " ") {
            event.preventDefault()
            forceOpen()
          }
        }}
      >
        {buttonRender}
      </span>
      {clicked && (
        <div ref={popoverRef} role="tooltip" className={popoverClass}>
          {popoverTitle !== undefined && (
            <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg border-gray-600 bg-gray-700">
              <h5 className="font-semibold text-white">{popoverTitle}</h5>
            </div>
          )}
          {popoverContent !== undefined && (
            <div className="px-3 py-2 text-gray-300 font-normal text-center">
              <span>{popoverContent}</span>
            </div>
          )}
          <div data-popper-arrow></div>
        </div>
      )}
    </>
  )
}

export default Popover
