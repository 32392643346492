import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { Controller, useFormContext } from "react-hook-form"

import PasswordValidation from "./PasswordValidation"

interface InputProps {
  id: string
  label?: string
  className?: string
  password?: string
}

const PasswordInput: React.FC<InputProps> = ({
  id,
  label,
  className,
  password,
}) => {
  const [shown, setShown] = useState(false)
  const type = shown ? "text" : "password"
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const errorMsg = errors?.[id]?.message

  const rules =
    password == null
      ? { required: `Requerido` }
      : {
          required: `Requerido`,
          minLength: {
            value: 8,
            message: `Mínimo 8 caracteres`,
          },
          validate: (value: string) => {
            if (!/[a-z]/.test(value)) {
              return "Mínimo una minúscula"
            }
            if (!/[A-Z]/.test(value)) {
              return "Mínimo una mayúscula"
            }
            if (!/\d/.test(value)) {
              return "Mínimo un número"
            }
            return true
          },
        }

  return (
    <div className={`${className as string} mb-4`}>
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor={id}
      >
        {label}
      </label>
      <Controller
        control={control}
        name={id}
        rules={rules}
        render={({ field }) => (
          <div className="relative">
            <input
              {...field}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
              id={id}
              type={type}
              required={true}
            />
            <button
              className="absolute right-4 top-2"
              onClick={(e: React.FormEvent) => {
                e.preventDefault()
                setShown(!shown)
              }}
            >
              {shown ? (
                <FontAwesomeIcon icon={faEyeSlash} className="text-gray-500" />
              ) : (
                <FontAwesomeIcon icon={faEye} className="text-gray-500" />
              )}
            </button>
          </div>
        )}
      />
      {errorMsg != null && (
        <p className="text-red-500 text-xs italic">{errorMsg as string}</p>
      )}
      {password != null && <PasswordValidation password={password} />}
    </div>
  )
}

export default PasswordInput
