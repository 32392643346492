import React, { useState } from "react"

import Modal from "../../components/common/modal/Modal"
import { ModalContext } from "./ModalContext"

interface ModalProviderProps {
  children: React.ReactNode
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [header, setHeader] = useState("")
  const [content, setContent] = useState<React.ReactNode>(null)

  const openModal = (header: string, content: React.ReactNode) => {
    setIsModalOpen(true)
    setHeader(header)
    setContent(content)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setHeader("")
    setContent(null)
  }

  return (
    <ModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
      {children}
      {isModalOpen && (
        <Modal header={header} onClose={closeModal}>
          {content}
        </Modal>
      )}
    </ModalContext.Provider>
  )
}
