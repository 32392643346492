import { isNullOrEmpty } from "../utils/validations"

const useDownloadFile = ({
  base64Data,
  filename,
}: {
  base64Data: string | undefined
  filename: string
}) => {
  const openDocument = () => {
    if (isNullOrEmpty(base64Data) || !(typeof base64Data === "string")) return

    const link = document.createElement("a")
    link.href = base64Data
    link.download = filename
    link.style.display = "none"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return { openDocument }
}

export default useDownloadFile
