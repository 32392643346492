import { faCircleInfo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import React from "react"

interface AlertProps {
  type: string
  children: React.ReactNode
}

const Alert: React.FC<AlertProps> = ({ type, children }) => {
  let color
  switch (type) {
    case "primary":
      color = "blue"
      break
    case "warning":
      color = "yellow"
      break
    case "danger":
      color = "red"
      break
    case "success":
      color = "green"
      break
    case "info":
      color = "purple"
      break
    case "dark":
      color = "gray"
      break
    default:
      color = "blue"
  }

  return (
    <div
      className={`alert border-2 border-${color}-200 p-2 rounded-lg flex items-center`}
    >
      <FontAwesomeIcon icon={faCircleInfo} color={color} className="mr-3" />
      <span>{children}</span>
    </div>
  )
}

Alert.propTypes = {
  type: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "dark",
  ]).isRequired,
  children: PropTypes.node.isRequired,
}

export default Alert
