import { faBars, faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { MagicMotion } from "react-magic-motion"

import Paths from "./../../routes/paths"

interface HeaderProps {
  showHamburger: boolean
  onHamburgerClick: any
}

const HeaderServices: React.FC<HeaderProps> = ({
  showHamburger,
  onHamburgerClick,
}) => {
  return (
    <MagicMotion>
      <header className={`w-full h-full bg-white`}>
        <div className="w-full h-full mx-auto px-4 lg:px-8">
          <div className={`w-full h-full flex items-center`} key={"exclude"}>
            {showHamburger && (
              <button
                type="button"
                className="inline-flex p-2 text-black transition-all duration-200 rounded-md focus:bg-gray-100 hover:bg-gray-100"
                onClick={onHamburgerClick}
              >
                <FontAwesomeIcon icon={faBars} size="lg" />
              </button>
            )}

            <div className="w-full h-full ml-auto flex items-center justify-end space-x-10">
              <a
                href={Paths.account}
                className="flex items-center bg-white hover:bg-gray-50"
              >
                <div className="rounded-full border-2 border-black px-2 py-1">
                  <FontAwesomeIcon icon={faUser} className="h-4 w-4" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </header>
    </MagicMotion>
  )
}

export default HeaderServices
