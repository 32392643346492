import React, { useState } from "react"
import { UseFormSetValue, UseFormWatch } from "react-hook-form"

interface ToggleProps {
  register: any
  setValue: UseFormSetValue<any>
  watch: UseFormWatch<any>
  id: string
  checked: boolean
  disabled?: boolean
  label?: string
  leftOption?: string
  rightOption?: string
  leftValue?: string
  rightValue?: string
}

export const ToggleSwitch: React.FC<ToggleProps> = ({
  checked,
  disabled,
  label,
}) => (
  <label className="relative inline-flex items-center mb-5 cursor-pointer">
    <input
      type="checkbox"
      className="sr-only peer"
      defaultChecked={checked}
      disabled={disabled}
    />
    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
      {label}
    </span>
    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
      {label}
    </span>
  </label>
)

export const ToggleOption: React.FC<ToggleProps> = ({
  register,
  setValue,
  watch,
  id,
  checked,
  leftOption,
  rightOption,
  leftValue,
  rightValue,
}) => {
  const [isChecked, setIsChecked] = useState(checked)
  const toggleValue = watch(id, checked ? rightValue : leftValue)

  const handleOnChange = () => {
    setIsChecked(!isChecked)
    setValue(id, !isChecked ? rightValue : leftValue)
  }

  return (
    <div className="flex justify-center items-center">
      <label className="inline-flex justify-center items-center cursor-pointer relative">
        <span
          className={`mr-3 text-sm ${
            isChecked
              ? "font-medium text-gray-300"
              : "font-semibold text-blue-700"
          }`}
        >
          {leftOption}
        </span>
        <input
          type="checkbox"
          className="sr-only peer"
          checked={isChecked}
          onChange={handleOnChange}
        />
        <input type="hidden" {...register(id)} value={toggleValue} />
        <div
          className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 peer-focus:ring-blue-800 rounded-full border-gray-600
        peer bg-blue-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white peer-checked:bg-blue-600
        after:content-[''] after:absolute after:top-[2px] after:start-50 after:transform-translate[-50%, -50%] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all"
        ></div>
        <span
          className={`ml-3 text-sm ${
            isChecked
              ? "font-semibold text-blue-700"
              : "font-medium text-gray-300"
          }`}
        >
          {rightOption}
        </span>
      </label>
    </div>
  )
}
