import {
  faBell,
  faCalculator,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import logo from "../../../../app/assets/images/page-icon.png"
import Popover from "../../../components/common/popover/Popover"
import CheckIcon from "../../../components/common/svg/CheckIcon"
import CrossIcon from "../../../components/common/svg/CrossIcon"
import { RightArrow } from "../../../components/common/svg/RightArrowIcon"
import Paths from "../../../routes/paths"

interface MobileMapping {
  title: string
  description: string
  price: string
  priceDescription: string
  account: string
  calculusDetermination: string
  calculusHaber: string
  support: string
  personal_notifications: string
  ai_notify: string
  link: string
}

export const pricingMap: MobileMapping[] = [
  {
    title: "Prueba",
    description: "",
    price: "$0",
    priceDescription: "Prueba gratuita",
    account: "Gratis",
    calculusDetermination: "5 cálculos / día",
    calculusHaber: "",
    support: "",
    personal_notifications: "",
    ai_notify: "",
    link: "?quota=free",
  },
  {
    title: "Estudio",
    description: "Ideal para pequeños estudios jurídicos",
    price: "$9.999",
    priceDescription: "Por mes*",
    account: "Gratis",
    calculusDetermination: "100 cálculos / día",
    calculusHaber: "20 cálculos / día",
    support: "YES",
    personal_notifications: "Hasta 5 expendientes activos",
    ai_notify: "",
    link: "?quota=estudio",
  },
  {
    title: "Empresa",
    description: "Ideal para pequeños estudios jurídicos",
    price: "$24.999",
    priceDescription: "Por mes*",
    account: "Gratis",
    calculusDetermination: "Ilimitados",
    calculusHaber: "Ilimitados",
    support: "YES",
    personal_notifications: "YES",
    ai_notify: "YES",
    link: "?quota=empresa",
  },
]

const transforInputMap = (input: string) => {
  switch (input) {
    case "YES":
      return (
        <span className="absolute right-10">
          <CheckIcon />
        </span>
      )
    case "":
      return (
        <span className="absolute right-10">
          <CrossIcon />
        </span>
      )
    default:
      return <span className="text-center font-bold">{input}</span>
  }
}

const displayItem = (item: MobileMapping) => (
  <div className="bg-white py-4 px-8 rounded-t-xl rounded-b-xl">
    <div className="border-b border-gray-200 py-2">
      <div className="px-4 py-2 text-base font-medium text-white text-center bg-blue-600 rounded-full w-min mr-auto ml-auto">
        {" "}
        {item.title}
      </div>
      <p className="mt-6 text-6xl font-bold text-center">{item.price}</p>
      <p className="mt-2 text-md font-normal text-gray-500 text-center">
        <span className="text-base">{item.priceDescription}</span>
      </p>
    </div>
    <div className="border-b border-gray-200 py-4">
      <p className="">
        <FontAwesomeIcon icon={faUser} className="mr-3 w-5" />
        Creación y mantenimiento de cuenta
      </p>
      <p className="font-bold text-center">{item.account}</p>
    </div>
    <div className="border-b border-gray-200 py-4">
      <p className="">
        <FontAwesomeIcon icon={faCalculator} className="mr-3 w-5" />
        Cálculo de determinación
        {transforInputMap(item.calculusDetermination)}
      </p>
    </div>
    <div className="border-b border-gray-200 py-4">
      <p className="relative">
        <FontAwesomeIcon icon={faCalculator} className="mr-3 w-5" />
        Cálculo de haber
        {transforInputMap(item.calculusHaber)}
      </p>
      <span className="font-bold text-center"></span>
    </div>
    <div className="border-b border-gray-200 py-4">
      <p className="relative">
        <FontAwesomeIcon icon={faPhone} className="mr-3 w-5" />
        Soporte técnico
        {transforInputMap(item.support)}
      </p>
    </div>
    <div className="border-b border-gray-200 py-4">
      <div className="relative">
        <FontAwesomeIcon icon={faBell} className="mr-3 w-5" />
        Notificaciones personales
        <Popover
          popoverContent={"Añadiremos esta funcionalidad pronto"}
          direction="right"
          buttonRender={
            <span className="ml-3 px-2 py-1 text-xs font-bold text-white bg-orange-400 rounded-full">
              Pronto{" "}
            </span>
          }
        />
        {transforInputMap(item.personal_notifications)}
      </div>
    </div>
    <div className="border-b border-gray-200 py-4">
      <div className="relative">
        <img src={logo} alt="logo" className="mr-3 w-5 inline-block" />
        A.I. Exp Notify
        <Popover
          popoverContent={"Añadiremos esta funcionalidad pronto"}
          direction="right"
          buttonRender={
            <span className="ml-3 px-2 py-1 text-xs font-bold text-white bg-orange-400 rounded-full">
              Pronto{" "}
            </span>
          }
        />
        {transforInputMap(item.ai_notify)}
      </div>
    </div>
    <div className="py-4 flex justify-center">
      <a
        href={Paths.subscription + item.link}
        title=""
        className="inline-flex items-center font-semibold text-blue-600 hover:text-blue-700"
      >
        Comenzar
        <RightArrow />
      </a>
    </div>
  </div>
)

const PricingMobile = (
  { selected }: { selected: number } = { selected: -1 },
) => {
  return (
    <div className="bg-ct-trademark-light sm:py-4 lg:py-24">
      <div className="flex justify-center items-center w-full">
        <div className="w-full flex flex-wrap">
          {selected === -1 &&
            pricingMap.map((item: MobileMapping, index: number) => (
              <div
                key={index}
                className={`my-3 mr-auto ml-auto md:min-w-[500px]`}
              >
                {displayItem(item)}
              </div>
            ))}
          {selected !== -1 && (
            <div className={`my-3 mr-auto ml-auto md:min-w-[500px]`}>
              {displayItem(pricingMap[selected])}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PricingMobile
