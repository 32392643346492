import PricingDesktop from "./PricingDesktop"
import PricingMobile from "./PricingMobile"

const PricingSection = () => {
  return (
    <section
      id="pricing"
      className="py-10 bg-ct-trademark-light sm:py-16 lg:py-24"
    >
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-xl mx-auto text-center">
          <h2 className="text-4xl font-bold text-black lg:text-5xl sm:text-5xl">
            Planes Mensuales
          </h2>
          <p className="mt-4 text-lg leading-relaxed text-gray-600">
            Elija el plan que mejor se adapte a sus necesidades como profesional
          </p>
        </div>

        <div className="hidden mt-10 lg:block">
          <PricingDesktop />
        </div>
        <div className="block mt-10 lg:hidden">
          <PricingMobile selected={-1} />
        </div>
      </div>
    </section>
  )
}

export default PricingSection
