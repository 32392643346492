export enum ColorType {
  success = "green",
  primary = "blue",
  danger = "red",
  warning = "yellow",
  info = "sky",
  default = "gray",
}

export enum TextSize {
  sm = "text-sm",
  md = "text-md",
  lg = "text-lg",
  default = "text-sm",
}
