import React, { useContext } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import Button from "../components/common/buttons/Button.js"
import Input from "../components/common/input/Input.js"
import PasswordInput from "../components/common/input/PasswordInput.js"
import { Spinner } from "../components/common/spinner/Spinner.js"
import { LoginRequest, RecoverUserRequest } from "../domain/dtos.js"
import { ToastContext } from "../providers/Toasts/ToastContext.js"
import Paths from "../routes/paths.js"
import {
  useModifyUserMutation,
  useValidateRecoverQuery,
} from "../services/sessionsApi.js"
import { getQueryParamValue } from "../utils/query"
import { isNullOrEmpty } from "../utils/validations.js"

const RestorePage = () => {
  const navigate = useNavigate()
  const { showToast } = useContext(ToastContext)
  const ssid = getQueryParamValue("ssid") as string
  const {
    isLoading: isLoadingValidation,
    data: userData,
    error: validateError,
  } = useValidateRecoverQuery(ssid)
  const [modifyUser, { error: modifyError, isLoading: isLoadingUserPut }] =
    useModifyUserMutation()

  const isLoading = isLoadingValidation || isLoadingUserPut

  const emailRecovered = userData?.email as string
  const nameRecovered = userData?.name as string

  const methods = useForm<RecoverUserRequest>({
    defaultValues: {
      password: "",
      password_confirm: "",
      ssid,
    },
  })

  const password = methods.watch("password", "")

  if (
    (!isNullOrEmpty(validateError) && (validateError as any)?.status === 403) ||
    (!isNullOrEmpty(modifyError) && (modifyError as any)?.status === 403)
  ) {
    setTimeout(() => {
      navigate(Paths.forgot + "?recoverLinkInvalid=true")
    }, 50)
  }

  const onSubmit = async (request: RecoverUserRequest) => {
    try {
      if (request.password !== request.password_confirm) {
        showToast({
          title: "Las contraseñas deben coincidir",
          message: "Verifica los datos ingresados y vuelve a intentar",
          type: "error",
        })
        return
      }
      await modifyUser(request).unwrap()
      showToast({
        title: "Cambio de contraseña exitoso!",
        message: "Ingrese al sistema ingresando sus nuevas credenciales",
        type: "success",
      })
      setTimeout(() => {
        navigate(Paths.login)
      }, 10)
    } catch (error: any) {
      if (error?.status !== 403) {
        showToast({
          title: "Error al modificar tu contraseña",
          message: "Verifica los datos ingresados y vuelve a intentar",
          type: "error",
        })
      }
    }
  }

  return (
    <>
      {isLoading && <Spinner align="center"></Spinner>}
      {!isLoading && (
        <section className="w-full max-w-lg mx-auto my-auto py-16">
          <div className="mx-auto flex justify-center items-center mt-10">
            <div className="">
              <h3 className="font-bold tracking-tight text-gray-900">
                Restaurá tu contraseña
              </h3>
              <h6 className="tracking-tight text-gray-900 mt-3">
                {nameRecovered !== null && (
                  <span className="font-bold">{nameRecovered}, </span>
                )}
                completá la información debajo para cambiar tu contraseña
              </h6>
              <FormProvider {...methods}>
                <form
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onSubmit={methods.handleSubmit(onSubmit)}
                  className="w-full"
                >
                  <div className="w-full flex flex-wrap mt-10">
                    <Input
                      id="email"
                      type="text"
                      label="Email"
                      value={emailRecovered}
                      disabled
                      className="w-full"
                    />
                    <PasswordInput
                      id="password"
                      label="Contraseña"
                      className="w-full"
                      password={password}
                    />
                    <PasswordInput
                      id="password_confirm"
                      label="Repetir Contraseña"
                      className="w-full"
                    />
                  </div>
                  <div className="w-full flex items-center justify-center flex-wrap mt-3">
                    <Button
                      color={"primary"}
                      size={"sm"}
                      outline={false}
                      className="w-full"
                    >
                      Restablecer contraseña
                    </Button>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default RestorePage
