import { createSlice } from "@reduxjs/toolkit"

const initialState = {}

export const determinationSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    },
  },
})

export default determinationSlice.reducer
