import React, { useEffect } from "react"

import useBreakpoint, { Breakpoints } from "../hooks/useBreakpoint"

const PolicyPage = () => {
  const breakpoint = useBreakpoint()
  window.scrollTo(0, 0)

  useEffect(() => {
    document.title = "Políticas de privacidad - Ai Need"
  }, [])

  const showEmail = (
    <span style={{ fontFamily: "monospace", fontWeight: "bold" }}>
      info@aineed.com.ar
    </span>
  )
  return (
    <div className="w-full flex flex-col items-center pt-12 pb-24">
      <div
        className={`mt-2 mb-8 text-justify w-full ${
          breakpoint >= Breakpoints.lg ? "px-10" : "px-5"
        }`}
      >
        <h1 className="font-bold text-center text-4xl">
          Política de privacidad
        </h1>
        <p className="mt-4 text-black/60">
          Última modificación: 24 de Mayo, 2024
        </p>
        <p>
          {" "}
          En AiNeed, proteger tu privacidad y la privacidad de cualquiera que
          reciba tus comunicaciones es muy importante para nosotros. El
          propósito de esta Política de Privacidad es hacer que AiNeed sea
          seguro y apropiado para todos. Nuestra Política de Privacidad se
          aplica a tu uso de los Servicios de AiNeed y describe el tipo de
          información que procesamos, cómo se procesa y por qué.{" "}
        </p>{" "}
        <p>
          {" "}
          AiNeed es una corporación cuyo nombre legal es AiNeed, SAS (Av.
          Francisco Ramírez 976, Paraná, Entre Ríos, Argentina,
          &quot;AiNeed&quot;). AiNeed proporciona servicios de correo
          electrónico transaccional basados en la nube (&quot;Servicios&quot;).{" "}
        </p>{" "}
        <p>
          {" "}
          En la medida en que la ley de protección de datos de la UE se aplica a
          nuestro Procesamiento de información sobre los Clientes, actuamos como
          el Controlador. En la medida en que la ley de protección de datos de
          la UE se aplica a nuestro procesamiento de los Datos del Cliente
          Sujetos a datos, el Cliente actúa como el Controlador y nosotros
          actuamos como el Procesador.{" "}
        </p>{" "}
        <h2>
          {" "}
          <strong>1. Definiciones</strong>{" "}
        </h2>{" "}
        <p>
          {" "}
          1.1. &quot;Controlador&quot; significa una entidad que determina los
          propósitos y medios del Procesamiento de Datos Personales.
        </p>
        <p>
          1.2. &quot;Cliente&quot; significa una persona o entidad que está
          registrada con AiNeed para usar los Servicios.
        </p>
        <p>
          1.3. &quot;Sujeto de Datos&quot; significa una persona natural cuyos
          Datos Personales AiNeed está procesando.
        </p>
        <p>
          1.4. &quot;Leyes de Protección de Datos&quot; significa todas las
          leyes de protección de datos y privacidad y regulaciones de la UE,
          EEE, y sus estados miembros, Suiza y el Reino Unido, aplicables al
          Procesamiento de Datos Personales.
        </p>
        <p>
          1.5. &quot;GDPR&quot; significa el Reglamento (UE) 2016/679 del
          Parlamento Europeo y del Consejo de 27 de abril de 2016 sobre la
          protección de las personas naturales con respecto al Procesamiento de
          Datos Personales y sobre la libre circulación de dichos datos y por el
          que se deroga la Directiva 95/46/CE (Reglamento General de Protección
          de Datos).
        </p>
        <p>
          1.6. &quot;Datos Personales&quot; significa cualquier información
          relacionada con una persona identificada o identificable natural.
        </p>
        <p>
          1.7. &quot;Procesamiento&quot; significa cualquier operación o
          conjunto de operaciones que se realiza sobre Datos Personales, ya sea
          o no por medios automáticos, tales como recopilación, registro,
          organización, almacenamiento, adaptación o alteración, recuperación,
          consulta, uso, divulgación por transmisión, difusión o de otra manera
          poner a disposición, alineación o combinación, bloqueo, borrado o
          destrucción. &quot;Proceso&quot;, &quot;Procesos&quot; y
          &quot;Procesado&quot; se interpretarán en consecuencia.
        </p>
        <p>
          1.8. &quot;Procesador&quot; significa una persona natural o jurídica,
          autoridad pública, agencia, u otro cuerpo que Procesa Datos Personales
          en nombre de el Controlador.
        </p>
        <p>
          1.9. &quot;Servicios&quot; significa cualquier producto o servicio
          proporcionado por AiNeed de acuerdo con los Términos de Uso de AiNeed
          (&quot;TOU&quot;).
        </p>
        <p>
          1.10. &quot;Nosotros&quot;, &quot;nos&quot;, y &quot;nuestro&quot; se
          refieren a AiNeed.{" "}
        </p>
        <p>
          1.11. &quot;Tú,&quot; &quot;tu,&quot; y &quot;tuyo&quot; se refieren
          al usuario.{" "}
        </p>
        <h2>
          <strong>2. Consentimiento</strong>
        </h2>
        <p>
          2.1. Al acceder a nuestros Servicios, aceptas nuestra Política de
          Privacidad y TOU, y consientes nuestra recopilación, almacenamiento,
          uso y divulgación de tu información personal como se describe en esta
          Política de Privacidad. Además, al usar nuestros Servicios estás
          aceptando las políticas y prácticas descritas en esta Política de
          Privacidad. Cada vez que visitas nuestro sitio web o el Servicio, y
          cualquier momento que nos proporcionas voluntariamente información,
          aceptas que estás consintiendo nuestra recopilación, uso, y
          divulgación de la información que proporcionas, y estás consintiendo
          recibir correos electrónicos o de otra manera ser contactado, como se
          describe en esta Política de Privacidad. Ya sea que te registres o
          crees una cuenta con nosotros, esta Política de Privacidad se aplica a
          todos los usuarios del sitio web y los Servicios.
        </p>
        <h2>
          <strong>3. ¿Qué información procesamos?</strong>
        </h2>
        <p>
          3.1. AiNeed recopila tanto &quot;Datos Personales&quot; como
          &quot;Datos No Personales&quot; sobre ti y procesará estos datos
          durante la duración de los Servicios, como se describe en el TOU:
        </p>
        <p>3.1.1. Datos Personales: </p>{" "}
        <ul>
          {" "}
          <li>
            {" "}
            <p>
              {" "}
              Información de los clientes: datos de identificación y contacto
              (nombre legal, detalles de contacto (correo electrónico), nombre
              del representante; información de facturación (dirección de
              facturación, detalles de la cuenta de MercadoPago, información de
              pago); información de la organización (nombre, dirección,
              ubicación geográfica, área de responsabilidad, código de IVA,
              código de registro), información de TI (direcciones IP, datos de
              uso, datos de cookies, datos de navegación en línea, datos de
              ubicación, datos del navegador); y{" "}
            </p>{" "}
          </li>{" "}
          <li>
            {" "}
            <p>
              {" "}
              Información del Sujeto de Datos: dirección de correo electrónico,
              IP, datos de ubicación, número de teléfono, datos del dispositivo
              y cualquier otra información que el Cliente nos proporcionará.{" "}
            </p>{" "}
          </li>{" "}
        </ul>{" "}
        <p>
          {" "}
          3.1.1.1. En cualquier momento, puedes iniciar sesión y cambiar esta
          información, o eliminarla. Puedes negarte a proporcionar Datos
          Personales a los Servicios, sin embargo, algunos de los Datos
          Personales que te pedimos que proporciones, por ejemplo, una dirección
          de correo electrónico, es obligatoria para un Servicio. Si te niegas a
          proporcionarlo, es posible que no podamos proporcionarte ese servicio.{" "}
        </p>{" "}
        {/* <p>
          {" "}
          3.1.1.2. Al proporcionar servicios de SMS, AiNeed utiliza el proveedor
          de servicios de SMS de terceros Bandwidth a quien AiNeed transfiere
          tus datos. Puedes encontrar el Aviso de Privacidad de Bandwidth{" "}
          <a href="https://www.bandwidth.com/privacy/">aquí</a>.{" "}
        </p>{" "} */}
        <p>
          {" "}
          3.1.1.2. Por favor, ten en cuenta que AiNeed no procesa ni almacena tu
          información de tarjeta de crédito. Una vez que realizas un pago, tu
          información de tarjeta de crédito se transfiere a nuestro procesador
          de pagos de terceros MercadoPago. Puedes encontrar su Política de
          Privacidad{" "}
          <a
            style={{ textDecoration: "underline" }}
            href="https://www.mercadopago.com.ar/privacidad"
          >
            aquí
          </a>
          .{" "}
        </p>{" "}
        <p>
          {" "}
          3.1.1.3. Cuando tienes suficientes créditos, puedes subir la lista de
          tus destinatarios de correo electrónico a AiNeed para fines de
          verificación de correo electrónico. Si subes la lista y no la
          verificas, la lista será eliminada de AiNeed. Si verificas la lista,
          se almacenará en AiNeed. Puedes eliminar la lista en cualquier
          momento.{" "}
        </p>{" "}
        <p>3.1.2. Datos No Personales: </p>{" "}
        <ul>
          {" "}
          <li>
            {" "}
            <p>
              {" "}
              Información del navegador y del dispositivo: ubicación, tipo de
              dispositivo, modelo, número, tipo de navegador o sistema
              operativo, la hora y fecha de acceso, actividad de navegación,
              resolución de pantalla, complementos, complementos y la versión de
              los Servicios que estás utilizando, y otra información que no te
              identifica personalmente; y{" "}
            </p>{" "}
          </li>{" "}
          <li>
            {" "}
            <p>
              {" "}
              Información de tu uso de los Servicios e información de otras
              fuentes: podemos recibir información sobre cómo y cuándo usas los
              Servicios, almacenarla en archivos de registro, u otros tipos de
              archivos asociados con tu cuenta y vincularla a otra información
              que recopilamos sobre ti. Además, podemos obtener información
              sobre ti o tus Sujetos de Datos de fuentes de terceros, como bases
              de datos públicas, plataformas de redes sociales, proveedores de
              datos de terceros y nuestros socios de marketing conjunto. Esta
              información puede incluir, por ejemplo, hora, fecha, navegador
              utilizado, información demográfica, información del dispositivo
              (como tipo de dispositivo, modelo, número, tipo de navegador o
              sistema operativo, direcciones IP), ubicación y datos de
              comportamiento en línea (como información sobre tu uso de sitios
              web de redes sociales, información de visualización de páginas y
              resultados de búsqueda y enlaces), acciones que has tomado, datos
              de uso anónimos, páginas de referencia/salida y URL, preferencias
              que envías y preferencias que se generan en función de los datos
              que envías y el número de clics.{" "}
            </p>{" "}
          </li>{" "}
        </ul>
        <h2>
          {" "}
          <strong>
            {" "}
            4. Propósitos de recopilación y Procesamiento de Datos Personales{" "}
          </strong>{" "}
        </h2>{" "}
        <p>
          {" "}
          4.1. AiNeed utiliza la información recopilada para la operación de los
          Servicios, para mantener la calidad del Servicio, para proporcionar
          estadísticas generales sobre el uso del Servicio, para promover y
          analizar los Servicios, para proporcionar soporte al cliente y enviar
          información sobre los Servicios, para proteger los derechos y la
          seguridad de los Clientes, Sujetos de Datos, terceros y los nuestros,
          para enviar contenido informativo o promocional personalizado y
          proporcionar sugerencias de acuerdo con las preferencias de marketing,
          para garantizar el cumplimiento de nuestro TOU y cumplir con los
          requisitos legales, para verificar la identidad y la información
          proporcionada, gestionar pagos, para entender la demografía, los
          intereses y las necesidades del Cliente, y otras tendencias entre los
          usuarios, y para otros fines comerciales.{" "}
        </p>{" "}
        <p>
          {" "}
          4.2. Podemos combinar Datos Personales con otra información que
          Procesamos para personalizar el contenido informativo y mejorar
          nuestra capacidad para proporcionar Servicios de acuerdo con las
          preferencias de marketing, restricciones o para publicidad, propósitos
          de segmentación o para desarrollar y proporcionarte productos y
          características más relevantes de acuerdo con esta Política de
          Privacidad.{" "}
        </p>{" "}
        <h2>
          {" "}
          <strong>5. Cómo recopilamos tu información</strong>{" "}
        </h2>{" "}
        <p>
          {" "}
          5.1. Datos proporcionados por nuestros Clientes: Los Clientes pueden
          importar a los Servicios Datos Personales que han recopilado de sus
          Sujetos de Datos u otros individuos. No tenemos una relación directa
          con los Sujetos de Datos de los Clientes ni con ningún individuo que
          no sean nuestros Clientes. Nuestro Procesamiento de Datos Personales
          se basa en el consentimiento libremente dado por el Sujeto de Datos y
          los Clientes son responsables de asegurarse de que tienen los permisos
          necesarios para que nosotros Procesemos Datos Personales sobre Sujetos
          de Datos u otros individuos.{" "}
        </p>{" "}
        <p>
          {" "}
          5.2. Tecnologías de seguimiento: en relación con la prestación de los
          Servicios, AiNeed utiliza:{" "}
        </p>{" "}
        <ul>
          {" "}
          <li>
            {" "}
            <p>
              {" "}
              Cookies: las cookies son pequeños archivos de datos que un sitio
              web transfiere al disco duro de tu computadora con fines de
              registro. La mayoría de los navegadores web están configurados
              para aceptar cookies, pero puedes cambiar esto en la configuración
              de tu navegador. Sin embargo, si no aceptas cookies, es posible
              que no puedas usar toda la funcionalidad de AiNeed. Usamos cookies
              para mejorar tu experiencia en AiNeed y al recopilar estos datos,
              podemos almacenar información para que no tengas que volver a
              ingresarla cada vez que visites AiNeed. También lo usamos para
              monitorear las métricas de los visitantes a AiNeed, como vistas de
              página, tiempo en el sitio, número de visitantes, etc;{" "}
            </p>{" "}
          </li>{" "}
          <li>
            {" "}
            <p>
              {" "}
              Balizas web: incluimos GIFs de un solo píxel (balizas web) en los
              correos electrónicos que enviamos, que nos permiten recopilar
              información sobre cuándo abres el correo electrónico y tu
              dirección IP, tu tipo de navegador o cliente de correo
              electrónico, y otros detalles similares. Usamos los datos de esas
              balizas web para crear informes sobre cómo se desempeñaron
              nuestros correos electrónicos y cómo podemos mejorarlos; y{" "}
            </p>{" "}
          </li>{" "}
          <li>
            {" "}
            <p>
              {" "}
              Identificadores únicos y tecnologías de seguimiento similares:
              podemos usar otras tecnologías de seguimiento para administrar los
              Servicios, rastrear tus movimientos alrededor de los Servicios,
              analizar tendencias, rastrear comportamientos, servir anuncios
              dirigidos y recopilar información demográfica, para medir el
              rendimiento de nuestras campañas de correo electrónico y para
              mejorar nuestras características para segmentos específicos de
              Clientes.{" "}
            </p>{" "}
          </li>{" "}
        </ul>{" "}
        <p>
          {" "}
          5.3. Monitoreo de datos de registro: podemos recopilar datos de
          registro cada vez que accedes a nuestra herramienta. Estos datos
          podrían incluir cosas como una dirección IP, tipo de navegador y
          versión, las páginas que visitas en AiNeed, y otras estadísticas de
          usuario. Cuando accedes a AiNeed con un dispositivo móvil, los datos
          de registro pueden incluir el tipo de dispositivo, tu ID único móvil,
          la dirección IP de tu dispositivo, sistema operativo móvil, y otras
          estadísticas móviles.{" "}
        </p>{" "}
        <p>
          {" "}
          5.4. Podemos usar servicios de terceros como Google Analytics, que
          recopilan y analizan datos de registro para ayudarnos a mejorar
          AiNeed, y ocasionalmente para usar para publicidad en línea dirigida.
          Nuestros socios de terceros pueden usar cookies u otras tecnologías de
          seguimiento para proporcionarte publicidad en otros sitios basada en
          tus actividades de navegación e intereses. Puedes encontrar más
          información sobre estas prácticas, incluyendo cómo optar por no
          recibir publicidad dirigida aquí:{" "}
          <a href="http://www.aboutads.info/choices/.">
            {" "}
            http://www.aboutads.info/choices/.{" "}
          </a>{" "}
        </p>{" "}
        <p>
          {" "}
          5.5. Nuestros socios de terceros pueden usar cookies u otras
          tecnologías de seguimiento para proporcionarte publicidad en otros
          sitios basada en tus actividades de navegación e intereses. Puedes
          encontrar más información sobre estas prácticas, incluyendo cómo optar
          por no recibir publicidad dirigida aquí:{" "}
          <a href="http://www.aboutads.info/choices/.">
            {" "}
            http://www.aboutads.info/choices/.{" "}
          </a>{" "}
        </p>{" "}
        {/* <p>
          {" "}
          5.6. Basado en los requisitos de la Ley de Protección de Privacidad en
          Línea de California, AiNeed declara que no corresponde a las señales
          de navegador de No Rastrear.{" "}
        </p>{" "} */}
        <h2>
          {" "}
          <strong>6. Transferencia a empresas de terceros</strong>{" "}
        </h2>{" "}
        <p>
          {" "}
          6.1. Nunca venderemos, alquilaremos o arrendaremos tus Datos
          Personales a un tercero, pero podemos compartir la información
          recopilada para los propósitos descritos en esta Política de
          Privacidad con terceros que ayudan a AiNeed a proporcionar, mejorar,
          promocionar o respaldar Servicios, que ayudan con nuestras operaciones
          comerciales y asisten en la entrega de nuestros Servicios, o que
          realizan servicios para nosotros, para prevenir daños a nuestra
          propiedad o por razones de seguridad, por ejemplo, procesadores de
          pagos, servicios de alojamiento, análisis, servicios de entrega de
          contenido, socios publicitarios, etc., de una manera que es
          consistente con esta Política de Privacidad. También podemos compartir
          información con terceros si se nos requiere hacerlo por ley o si
          violas nuestro TOU.{" "}
        </p>{" "}
        <p>
          {" "}
          6.2. Podemos divulgar Datos No Personales para cualquier propósito
          porque esta información no puede ser utilizada para identificarte o a
          otra persona.{" "}
        </p>
        <h2>
          {" "}
          <strong>
            7. Comunicaciones por correo electrónico y opción de exclusión
          </strong>{" "}
        </h2>{" "}
        <p>
          {" "}
          7.1. De vez en cuando puedes recibir correos electrónicos de nosotros
          relacionados con tu uso de AiNeed. Basándonos en los Datos Personales
          que nos proporcionas, podemos comunicarnos contigo en respuesta a tus
          consultas para proporcionar los Servicios que solicitas y para
          gestionar tu cuenta. También podemos usar tus Datos Personales para
          enviarte actualizaciones y otras comunicaciones promocionales. Cada
          correo electrónico contendrá un enlace para poder optar por no
          recibirlo. Todavía podemos enviar mensajes importantes sobre asuntos
          administrativos, actualizaciones, disputas y problemas de servicio al
          cliente que son necesarios para proporcionar los Servicios.{" "}
        </p>{" "}
        <p>
          {" "}
          7.2. Si un Sujeto de Datos ya no quiere ser contactado por uno de
          nuestros Clientes por correo electrónico, deberían darse de baja
          directamente de ese correo electrónico del Cliente o contactar al
          Cliente directamente para actualizar o eliminar los datos.{" "}
        </p>{" "}
        {/* <p>
          {" "}
          7.3. Cada SMS transaccional o de marketing que envíes usando AiNeed
          debe incluir instrucciones claras sobre cómo optar por no participar,
          incluyendo la frase: &quot;Responde STOP para darte de baja&quot; al
          final del mensaje inicial enviado al consumidor, o &quot;responde STOP
          para cancelar&quot;. Una vez que se dan de baja, debes dejar de
          enviarles SMS.{" "}
        </p>{" "} */}
        <h2>
          {" "}
          <strong>8. Seguridad de tu información</strong>{" "}
        </h2>{" "}
        <p>
          {" "}
          8.1. AiNeed toma precauciones razonables, medidas de seguridad
          técnicas y organizativas para garantizar un nivel de seguridad
          apropiado al riesgo, sigue las mejores prácticas de la industria para
          proteger tus Datos Personales de cualquier violación de seguridad no
          autorizada o ilegal que conduzca a la destrucción, pérdida,
          alteración, divulgación no autorizada o acceso a Datos Personales y
          para preservar la seguridad y confidencialidad de los datos. Sin
          embargo, estas medidas no garantizan que tu información no será
          accedida, divulgada, alterada o destruida por violación de tales
          precauciones. Al usar nuestro Servicio, reconoces que entiendes y
          aceptas asumir estos riesgos.{" "}
        </p>{" "}
        <p>
          {" "}
          8.2. El Cliente es responsable de revisar la información puesta a
          disposición por AiNeed en relación con su seguridad de datos y hacer
          una determinación independiente de si los Servicios cumplen con los
          requisitos del Cliente y las obligaciones legales bajo las Leyes de
          Protección de Datos. Además, el Cliente es responsable de su uso
          seguro de los Servicios, incluyendo la protección de sus credenciales
          de autenticación de cuenta, protegiendo la seguridad de los Datos
          Personales cuando están en tránsito hacia y desde los Servicios, y
          tomando cualquier medida apropiada para cifrar de forma segura o hacer
          una copia de seguridad de cualquier Dato Personal subido a los
          Servicios.{" "}
        </p>
        <h2>
          {" "}
          <strong>
            {" "}
            9. Protección de los correos electrónicos, números de teléfono y
            datos del Sujeto de Datos{" "}
          </strong>{" "}
        </h2>{" "}
        <p>
          {" "}
          9.1. Nos reservamos el derecho de monitorear tus correos electrónicos,
          contenido y datos de respuesta de vez en cuando para asegurarnos de
          que cumplen con nuestro TOU, nunca compartiremos, venderemos o
          alquilaremos tus datos a nadie por ningún motivo.{" "}
        </p>{" "}
        {/* <p>
          {" "}
          9.2. Nos reservamos el derecho de monitorear tus SMS, contenido y
          datos de respuesta de vez en cuando para asegurarnos de que cumplen
          con nuestro TOU. Nunca compartiremos, venderemos o alquilaremos tus
          datos a nadie por ningún motivo.{" "}
        </p>{" "} */}
        <p>
          {" "}
          9.2. Nuestros centros de almacenamiento de datos están en Estados
          Unidos y tienen un certificado de seguridad de almacenamiento de
          información (ISO 27001) así como un certificado de gestión de
          servicios de TI (ISO 20000) por lo que puedes estar seguro de que los
          datos de tu Sujeto de Datos están seguros con nosotros.{" "}
        </p>{" "}
        <h3>
          {" "}
          <strong>10. Derechos de los clientes</strong>{" "}
        </h3>{" "}
        <p>
          10.1. Tienes los siguientes derechos con respecto a tus Datos
          Personales:
        </p>{" "}
        <ul>
          {" "}
          <li>
            {" "}
            <p>
              {" "}
              <strong>Derecho de Acceso</strong>. Tienes el derecho de solicitar
              la divulgación de las piezas específicas de tus Datos Personales
              que hemos recopilado sobre ti en un formato portátil y, cuando sea
              técnicamente factible, fácilmente utilizable.{" "}
            </p>{" "}
          </li>{" "}
          <li>
            {" "}
            <p>
              {" "}
              <strong>Derecho a Saber.</strong> Tienes el derecho de verificar
              si hemos recopilado Datos Personales sobre ti y conocer los
              detalles de los mismos.{" "}
            </p>{" "}
          </li>{" "}
          <li>
            {" "}
            <p>
              {" "}
              <strong>Derecho a la Eliminación.</strong> Puedes solicitar la
              eliminación de tus Datos Personales de acuerdo con la Cláusula 11
              de esta Política de Privacidad.{" "}
            </p>{" "}
          </li>{" "}
          <li>
            {" "}
            <p>
              {" "}
              <strong>Derecho a Optar por no participar.</strong> Tienes el
              derecho de optar por no compartir tus Datos Personales para
              publicidad comportamental de contexto cruzado a terceros. Puedes
              ejercer este derecho visitando nuestra Configuración de Cookies y
              desactivando las cookies funcionales, de rendimiento y de
              orientación.{" "}
            </p>{" "}
          </li>{" "}
          <li>
            {" "}
            <p>
              {" "}
              <strong>
                {" "}
                Derecho a objetar el procesamiento de tus Datos Personales.{" "}
              </strong>
              Tienes el derecho de objetar el procesamiento de tus Datos
              Personales, incluyendo la elaboración de perfiles.{" "}
            </p>{" "}
          </li>{" "}
          <li>
            {" "}
            <p>
              {" "}
              <strong>Derecho a corregir tus Datos Personales.</strong> Tienes
              el derecho de actualizar la información inexacta que procesamos
              sobre ti modificándola en tu cuenta de AiNeed.{" "}
            </p>{" "}
          </li>{" "}
          <li>
            {" "}
            <p>
              {" "}
              <strong>
                Derecho a ejercer derechos sin discriminación.
              </strong>{" "}
              Tienes el derecho de no recibir un trato discriminatorio si
              ejerces los derechos que te confiere la ley de privacidad
              aplicable.{" "}
            </p>{" "}
          </li>{" "}
        </ul>{" "}
        <p>
          {" "}
          10.2. Ten en cuenta que estos derechos pueden estar limitados por las
          leyes aplicables y dependen de la factibilidad técnica.{" "}
        </p>{" "}
        <p>
          {" "}
          10.3. Si no puedes ejercer estos derechos a través de tu cuenta de
          AiNeed, por favor contáctanos por correo electrónico a {showEmail}.
          Podemos requerir información adicional o documentos de identificación
          de ti para verificar tu identidad y procesar tu solicitud.{" "}
        </p>{" "}
        {/* <p>
          {" "}
          10.4. Si eres residente de California y quieres implementar tus
          derechos bajo la Ley de Privacidad del Consumidor de California, por
          favor consulta la Cláusula 15 de esta Política de Privacidad.{" "}
        </p>{" "} */}
        <h2>
          {" "}
          <strong>11. Retención de datos</strong>{" "}
        </h2>
        <p>
          {" "}
          11.1. A menos que solicites específicamente que eliminemos tus Datos
          Personales, los conservaremos durante el tiempo que necesitemos para
          cumplir con nuestro TOU, brindarte Servicios y mantener la operación
          efectiva de nuestro negocio. En caso de que solicites la eliminación
          de tus Datos Personales, será efectiva dentro de los próximos 30 días,
          después de este período de 30 días los datos se eliminan de forma
          permanente y no habrá opción para recuperar los datos. Si deseas
          revertir la eliminación de los datos en el período de 30 días, debes
          contactarnos directamente con dicha solicitud.{" "}
        </p>{" "}
        <p>
          {" "}
          11.2. Si nos pides que eliminemos tus Datos Personales, aún podemos
          estar obligados a retener algunos registros personales por los
          siguientes motivos: (i) para seguir la ley o las reglas (como mantener
          registros de transacciones), (ii) para tratar problemas legales o
          defendernos en reclamaciones legales, y (iii) para proteger contra el
          fraude o el abuso en nuestro Servicio. Esto implica que diferentes
          tipos de Datos Personales pueden ser conservados durante diferentes
          períodos de tiempo.{" "}
        </p>{" "}
        <p>
          {" "}
          11.3. A veces, es posible que no podamos eliminar, anonimizar o
          ocultar tus Datos Personales de inmediato debido a razones técnicas,
          legales o operativas. En estas situaciones, tomaremos medidas
          razonables para aislar de forma segura tus Datos Personales de
          cualquier Procesamiento adicional hasta que podamos eliminar,
          anonimizar o desidentificarlos de manera adecuada.{" "}
        </p>{" "}
        <p>
          {" "}
          11.4. Si tu cuenta está inactiva durante 6 meses o más, AiNeed se
          reserva el derecho de eliminar permanentemente tu cuenta con todos sus
          datos, incluyendo tus cálculos, registros de actividad, registros de
          clientes, configuraciones de los servicios de notificaciones
          personales, y tu contenido como correos electrónicos. Si AiNeed decide
          eliminar tu cuenta, se te informará a través de notificaciones por
          correo electrónico dándote tiempo para exportar tus datos o reactivar
          tu cuenta, dichas notificaciones se enviarán 30 días, 7 días y 1 día
          antes de la eliminación permanente de tus datos. Ten en cuenta que una
          vez que los datos se eliminan de forma permanente, no habrá opción
          para recuperarlos.{" "}
        </p>{" "}
        <h2>
          {" "}
          <strong>12. Enlaces a otros sitios web</strong>{" "}
        </h2>{" "}
        <p>
          {" "}
          12.1. Como parte del Servicio, podemos proporcionar enlaces o
          compatibilidad con otros sitios web o aplicaciones. Sin embargo, no
          somos responsables de las prácticas de privacidad empleadas por esos
          sitios web o la información o contenido que contienen. Esta Política
          de Privacidad se aplica únicamente a la información recopilada por
          nosotros a través del Servicio. Por lo tanto, esta Política de
          Privacidad no se aplica a tu uso de un sitio web de terceros al que
          accedas seleccionando un enlace a través de nuestro Servicio. En la
          medida en que accedas o uses el Servicio a través de otro sitio web o
          aplicación, entonces la política de privacidad de ese otro sitio web o
          aplicación se aplicará a tu acceso o uso de ese sitio o aplicación.
          Alentamos a nuestros usuarios a leer las declaraciones de privacidad
          de otros sitios web antes de proceder a usarlos.{" "}
        </p>{" "}
        {/* <h2>
          {" "}
          <strong>13. Anexo de Procesamiento de Datos</strong>{" "}
        </h2>{" "}
        <p>
          {" "}
          13.1. Hemos creado nuestro
          {
            " “} <a href=“https://www.AiNeed.com/legal/data-processing-addendum”> Anexo de Procesamiento de Datos </a>{” "
          }{" "}
          para cubrir los requisitos del GDPR en lo que respecta a los servicios
          de software de comunicaciones transaccionales basados en la nube y
          alentamos a todos nuestros Clientes a revisarlo y estar de acuerdo con
          los términos del Anexo de Procesamiento de Datos.{" "}
        </p> */}
        <h2>
          {" "}
          <strong>13. Edad de consentimiento</strong>{" "}
        </h2>{" "}
        <p>
          {" "}
          13.1. Al utilizar el Servicio, representas que tienes al menos 18 años
          de edad.{" "}
        </p>{" "}
        {/* <h2>
          {" "}
          <strong>15. Derechos de privacidad de California </strong>{" "}
        </h2>{" "}
        <p>
          {" "}
          15.1. Esta sección se aplica exclusivamente a las personas que residen
          en California (“<strong>Clientes de California</strong>”). Describe
          nuestros procedimientos para recopilar, usar y compartir Datos
          Personales de los Clientes de California en nuestra capacidad como
          negocio de acuerdo con la Ley de Privacidad del Consumidor de
          California (“<strong>CCPA</strong>”), junto con los derechos asociados
          con respecto a dichos Datos Personales. Para los propósitos de esta
          cláusula, “Datos Personales” se refiere a la definición de
          “Información Personal” tal como se describe en la CCPA.{" "}
        </p>{" "}
        <p>
          {" "}
          15.2. Los Clientes de California tienen la capacidad de ejercer los
          derechos subsiguientes con respecto a sus Datos Personales, pero estos
          derechos no son incondicionales, y puede haber casos en los que
          estamos autorizados para rechazar tu solicitud de acuerdo con la CCPA:{" "}
        </p>{" "}
        <ul>
          {" "}
          <li>
            {" "}
            <p>
              {" "}
              <strong>Derecho a Saber:</strong> Puedes solicitar detalles sobre
              nuestra recopilación y uso de tus Datos Personales, incluyendo
              categorías de datos recopilados, propósitos, compartición con
              terceros y piezas específicas recopiladas. AiNeed responderá a un
              máximo de dos (2) solicitudes dentro de un período de doce (12)
              meses.{" "}
            </p>{" "}
          </li>{" "}
          <li>
            {" "}
            <p>
              {" "}
              <strong>Derecho de Acceso</strong>: Eres capaz de acceder a todos
              los Datos Personales transferidos a nosotros en tu cuenta de
              AiNeed. También puedes solicitar detalles sobre cómo hemos
              recopilado y utilizado tus Datos Personales durante los últimos
              doce (12) meses, y AiNeed responderá a un máximo de dos (2)
              solicitudes. Ten en cuenta que puede haber restricciones legales
              que nos impidan divulgar ciertos Datos Personales si representa un
              riesgo sustancial para la seguridad de la información, nuestros
              sistemas o tu cuenta.{" "}
            </p>{" "}
          </li>{" "}
          <li>
            {" "}
            <p>
              {" "}
              <strong>Derecho a Corregir:</strong> Si encuentras alguna
              inexactitud en tus Datos Personales que tenemos, o si tus Datos
              Personales cambian, puedes hacer los cambios respectivos en tu
              cuenta de AiNeed o puedes notificarnos y actualizaremos
              prontamente nuestros registros para asegurar que reflejen la
              información precisa.{" "}
            </p>{" "}
          </li>{" "}
          <li>
            {" "}
            <p>
              {" "}
              <strong>Derecho a la Eliminación:</strong> Puedes solicitar la
              eliminación de tus Datos Personales según la Cláusula 11 de esta
              Política de Privacidad. AiNeed puede eliminar, desidentificar o
              agregar tus datos tras tal solicitud, confirmando la información
              antes de la eliminación.{" "}
            </p>{" "}
          </li>{" "}
          <li>
            {" "}
            <p>
              {" "}
              <strong>Optar por no participar en la Compartición:</strong>{" "}
              AiNeed utiliza servicios para entregarte anuncios personalizados.
              Bajo la CCPA, nuestra utilización de algunos de estos servicios
              puede ser etiquetada como compartir tus Datos Personales con los
              socios publicitarios que proporcionan estos servicios. Tienes la
              opción de optar por no participar en la compartición de tus Datos
              Personales visitando nuestra Configuración de Cookies y
              desactivando las cookies funcionales, de rendimiento y de
              orientación.{" "}
            </p>{" "}
          </li>{" "}
          <li>
            {" "}
            <p>
              {" "}
              <strong>No Venta de Datos Personales:</strong> Si has activado la
              función de optar por no participar, automáticamente serás excluido
              de cualquier compartición al interactuar con nuestros Servicios.
              AiNeed se adhiere a las regulaciones de la CCPA al no vender tus
              Datos Personales.{" "}
            </p>{" "}
          </li>{" "}
          <li>
            {" "}
            <p>
              {" "}
              <strong>
                Procesamiento Limitado de Datos Personales Sensibles:
              </strong>{" "}
              Nosotros no recopilamos ningún Dato Personal Sensible. Sin
              embargo, no podemos controlar si nuestros Clientes usan o divulgan
              Datos Personales Sensibles de sus Suscriptores. Si eres un
              Suscriptor de nuestros Clientes, por favor contáctalos
              directamente para consultas sobre tus Datos Personales Sensibles.{" "}
            </p>{" "}
          </li>{" "}
          <li>
            {" "}
            <p>
              {" "}
              <strong>Derecho a la No Discriminación.</strong> No participaremos
              en discriminación contra ti por ejercer cualquiera de tus derechos
              de la CCPA.{" "}
            </p>{" "}
          </li>{" "}
        </ul>
        <h2>
          {" "}
          <strong>14. Edad de consentimiento</strong>{" "}
        </h2>{" "}
        <p>
          {" "}
          15.3. Si no puedes ejercer estos derechos a través de tu cuenta de
          AiNeed, los Clientes de California pueden ejercer estos derechos sobre
          sus Datos Personales poniéndose en contacto con nosotros por correo
          electrónico en {showEmail}.{" "}
        </p>{" "}
        <p>
          {" "}
          15.4. AiNeed en términos de CCPA actúa como un proveedor de servicios
          y procesamos Datos Personales en nombre de nuestros Clientes. Por
          favor, dirige cualquier solicitud de acceso o eliminación de tus Datos
          Personales a nuestros Clientes con quienes tienes una relación
          directa.{" "}
        </p>{" "} */}
        {/* <h2>
          {" "}
          <strong>14. Marco de Privacidad de Datos</strong>{" "}
        </h2>{" "}
        <p>
          {" "}
          14.1. AiNeed, SAS ha certificado al Departamento de Comercio que se
          adhiere a los Principios del Marco de Privacidad de Datos. Cuando
          proporciona Servicios a Clientes fuera del Área Económica Europea
          (EEA), Reino Unido y Suiza, AiNeed, SAS opera de acuerdo con los
          Marcos de Privacidad de Datos EU-U.S. y Swiss-U.S. (&quot;DPF&quot;),
          así como la Extensión del Reino Unido al EU-U.S. DPF, según lo
          estipulado por el Departamento de Comercio de los EE. UU. Esta
          adhesión se extiende a la recopilación, procesamiento, utilización y
          retención de Datos Personales de estas regiones. Si hay algún
          conflicto entre los términos de esta Política de Privacidad y los
          principios del EU-U.S. DPF y/o los principios del Swiss-U.S. DPF, los
          principios prevalecerán. Para obtener más información sobre el DPF y
          para acceder a nuestra certificación, por favor visita el sitio web
          del DPF{" "}
          <a href="https://www.dataprivacyframework.gov/s/?hl=en_US">
            {" "}
            aquí
          </a>.{" "}
        </p>{" "}
        <p>
          {" "}
          14.2 AiNeed, SAS cae bajo la autoridad regulatoria de la Comisión
          Federal de Comercio de los EE. UU., que posee poderes de investigación
          y aplicación. Además, tienes la opción de presentar una queja ante tu
          autoridad local de protección de datos, y colaboraremos con ellos para
          abordar tus preocupaciones. Bajo condiciones específicas, como se
          describe en{" "}
          <a href="https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf?tabset-35584=2&hl=en_US">
            {" "}
            Anexo I a los Principios del DPF{" "}
          </a>{" "}
          , el DPF proporciona el derecho a buscar un arbitraje vinculante para
          resolver quejas que permanecen sin resolverse a través de otros
          canales.{" "}
        </p>{" "}
        <p>
          {" "}
          14.3. En cumplimiento con el EU-U.S. DPF y la Extensión del Reino
          Unido al EU-U.S. DPF y el Swiss-U.S. DPF, AiNeed, SAS se compromete a
          resolver quejas relacionadas con los principios del DPF sobre nuestra
          recopilación y uso de tus Datos Personales. Los individuos de la UE y
          del Reino Unido y los individuos suizos con consultas o quejas con
          respecto a nuestro manejo de datos personales recibidos en dependencia
          del EU-U.S. DPF y la Extensión del Reino Unido al EU-U.S. DPF, y el
          Swiss-U.S. DPF deben contactar primero a AiNeed, SAS por correo
          electrónico a {showEmail}.{" "}
        </p>{" "}
        <p>
          {" "}
          14.4. En cumplimiento con el EU-U.S. DPF y la Extensión del Reino
          Unido al EU-U.S. DPF y el Swiss-U.S. DPF, AiNeed, SAS se compromete a
          cooperar y cumplir respectivamente con el consejo del panel
          establecido por las autoridades de protección de datos de la UE (DPAs)
          y la Oficina del Comisionado de Información del Reino Unido (ICO) y el
          Comisionado Federal de Protección de Datos e Información de Suiza
          (FDPIC) con respecto a las quejas no resueltas sobre nuestro manejo de
          Datos Personales recibidos en dependencia del EU-U.S. DPF y la
          Extensión del Reino Unido al EU-U.S. DPF y el Swiss-U.S. DPF.{" "}
        </p>{" "}
        <p>
          {" "}
          14.5. Cuando transferimos tu información personal a terceros fuera de
          tu jurisdicción, incluyendo a países con diferentes leyes de
          protección de datos, mantenemos la responsabilidad por el proceso de
          transferencia. Implementamos salvaguardas apropiadas para asegurar que
          tu información personal permanece protegida de acuerdo con esta
          Política de Privacidad y las regulaciones relevantes. Algunos
          destinatarios pueden estar en países con decisiones de adecuación de
          la Comisión Europea, el Gobierno del Reino Unido, o el Gobierno Suizo,
          permitiendo transferencias permitidas bajo el Artículo 45 del GDPR. En
          casos sin tales decisiones, podemos usar Cláusulas Contractuales
          Estándar aprobadas por la Comisión Europea u otros mecanismos de
          transferencia reconocidos para proteger tus datos personales. Nuestro
          compromiso es asegurar que todas las transferencias de tu información
          personal cumplan con las leyes de protección de datos aplicables y
          defiendan tus derechos y libertades.{" "}
        </p> */}
        <h2>
          {" "}
          <strong>14. Fusión o adquisición</strong>{" "}
        </h2>{" "}
        <p>
          {" "}
          14.1. En el caso de que pasemos por una transacción comercial como una
          fusión, adquisición por otra compañía, o venta de todos o una parte de
          nuestros activos, tus Datos Personales pueden estar entre los activos
          transferidos. Reconoces y consientes que tales transferencias pueden
          ocurrir y están permitidas por esta Política de Privacidad, y que
          cualquier adquirente de nuestros activos puede continuar Procesando
          tus Datos Personales según lo establecido en esta Política de
          Privacidad.{" "}
        </p>{" "}
        <h2>
          {" "}
          <strong>15. AiNeed como Controlador</strong>{" "}
        </h2>{" "}
        <p>
          {" "}
          15.1. AiNeed actúa como un Controlador de Datos Personales del
          Cliente, sus empleados, contratistas, candidatos, afiliados y otras
          terceras partes que no se consideran como un Cliente. AiNeed se
          compromete a cumplir con los requisitos legales al procesar Datos
          Personales como un Controlador e implementa medidas técnicas y
          organizativas apropiadas para asegurar un nivel de seguridad de los
          Datos Personales según lo requerido bajo el GDPR y las Leyes de
          Protección de Datos aplicables.{" "}
        </p>{" "}
        <h2>
          {" "}
          <strong>16. Cambios</strong>{" "}
        </h2>{" "}
        <p>
          {" "}
          16.1. AiNeed se reserva el derecho de cambiar esta Política de
          Privacidad en cualquier momento. Si decidimos cambiar esta Política de
          Privacidad, publicaremos estos cambios en esta página para que siempre
          estés al tanto de qué información recopilamos, cómo la usamos y bajo
          qué circunstancias la divulgamos. Todos los cambios son efectivos
          inmediatamente después de su publicación y sugerimos que revises
          periódicamente esta Política de Privacidad si te preocupa cómo se usa
          tu información.{" "}
        </p>{" "}
        <h2>
          {" "}
          <strong>17. Contactando a AiNeed</strong>{" "}
        </h2>{" "}
        <p>
          {" "}
          17.1. Por favor contáctanos en cualquier momento por correo
          electrónico a {showEmail} si tú:{" "}
        </p>{" "}
        <ul>
          {" "}
          <li>
            {" "}
            <p>
              {" "}
              Deseas solicitar acceso a la información que tenemos sobre ti,
              corregir, modificar, eliminar o actualizar los Datos Personales
              que nos has proporcionado; o{" "}
            </p>{" "}
          </li>{" "}
          <li>
            {" "}
            <p>
              {" "}
              Tienes alguna pregunta con respecto a esta Política de Privacidad
              o las prácticas de este sitio, deseas retirar tu consentimiento
              para la recopilación continua, te gustaría objetar que se utilicen
              tus Datos Personales, o tienes alguna pregunta adicional.{" "}
            </p>{" "}
          </li>{" "}
        </ul>
      </div>
    </div>
  )
}

export default PolicyPage
