import { UseFormSetValue } from "react-hook-form"

// const camelToSnakeKey = (key: string) => {
//   return key.split("_").reduce((substr, current) => {
//     return substr + current.charAt(0).toUpperCase() + current.slice(1)
//   })
// }

// export const camelToSnake = (obj: object) => {
//   const newObj: object = {}
//   for (const property in obj) {
//     const key = camelToSnakeKey(property)
//     newObj[key] = obj[property]
//   }
//   return newObj
// }

export const numbersToQueryParams = (numbers?: number[]): string => {
  return numbers !== undefined
    ? numbers.map((number) => `?deuda=${number}`).join("")
    : ""
}

const setValueIfDefined = <T extends UseFormSetValue<any>>(
  setValue: T,
  key: string,
  value: any,
) => {
  if (value !== undefined) {
    setValue(key, value)
  }
}

export const initializeUseFormObject = (
  setValue: UseFormSetValue<any>,
  object: any,
) => {
  for (const [key, value] of Object.entries(object)) {
    setValueIfDefined(setValue, key, value)
  }
}
