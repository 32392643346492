import { createBrowserRouter } from "react-router-dom"

import AccountCnt from "../components/Account/AccountCnt"
import DeterminationCnt from "../components/Determination/DeterminationForm/DeterminationCnt"
import LayoutLanding from "../layout/Landing/LayoutLanding"
import LayoutLogin from "../layout/Login/LayoutLogin"
import LayoutServices from "../layout/Services/LayoutServices"
import ErrorPage from "../pages/ErrorPage"
import HomePage from "../pages/HomePage"
import LoginPage from "../pages/LoginPage"
import CheckoutPage from "../pages/PaymentPage"
import PolicyPage from "../pages/PolicyPage"
import RecoverPage from "../pages/RecoverPage"
import RedirectFromGoogle from "../pages/RedirectFromGoogle"
import RegisterPage from "../pages/RegisterPage"
import RestorePage from "../pages/RestorePage"
import SubscriptionPage from "../pages/SubscriptionPage"
import TermsConditionsPage from "../pages/TermsConditionsPage"
import Paths from "./paths"

const router = createBrowserRouter([
  {
    path: "/",
    element: <LayoutLanding />,
    children: [
      {
        path: Paths.home,
        element: <HomePage />,
      },
      {
        path: Paths.policy,
        element: <PolicyPage />,
      },
      {
        path: Paths.terms,
        element: <TermsConditionsPage />,
      },
      {
        path: Paths.subscription,
        element: <SubscriptionPage />,
      },
      {
        path: Paths.checkout,
        element: <CheckoutPage />,
      },
      {
        path: Paths.login_successful,
        element: <RedirectFromGoogle />,
      },
    ],
  },
  {
    path: "/",
    element: <LayoutLogin />,
    children: [
      {
        path: Paths.register,
        element: <RegisterPage />,
      },
      {
        path: Paths.login,
        element: <LoginPage />,
      },
      {
        path: Paths.forgot,
        element: <RecoverPage />,
      },
      {
        path: Paths.reset,
        element: <RestorePage />,
      },
    ],
  },
  {
    path: "/",
    element: <LayoutServices />,
    children: [
      {
        path: Paths.account,
        element: <AccountCnt />,
      },
      {
        path: Paths.determination,
        element: <DeterminationCnt />,
      },
      {
        path: Paths.invalid_pension,
        element: <DeterminationCnt />,
      },
      {
        path: Paths.dead_pension,
        element: <DeterminationCnt />,
      },
    ],
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
])

export default router
