import React, { useEffect, useRef, useState } from "react"

import { Regimen } from "../../../domain/entities"
import useKeyboard from "../../../hooks/useKeyboard"
import useOutboundClick from "../../../hooks/useOutboundClick"
import { LookUpIcon } from "../../common/svg/LookUpIcon"
import RegimenOption from "./RegimenOption"

interface Props {
  options: Regimen[] | undefined
  sexoSelected: string
  float: boolean
  optionSelected: number
  onSelected: (id: number) => void
}

const SelectRegimenAportes: React.FC<Props> = ({
  options,
  sexoSelected,
  float,
  optionSelected,
  onSelected,
}) => {
  const searchRef = useRef(null)
  const dropDownRef = useRef(null)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [nextDropShouldShow, setNextDropShouldShow] = useState(false)
  const [preselect, setPreselect] = useState(0)
  const [filter, setFilter] = useState("")
  const [filtered, setFiltered] = useState<Regimen[]>(options as Regimen[])

  useEffect(() => {
    setFiltered(
      options?.filter(
        (regimen) =>
          Boolean(
            regimen.etiquetas
              ?.split(",")
              .some((tag: string) => tag.includes(filter)),
          ) || regimen.nombre.match(new RegExp(filter, "i")),
      ) as Regimen[],
    )
  }, [filter, options])

  useEffect(() => {
    if (nextDropShouldShow && optionSelected === 0) {
      if (searchRef?.current !== null) (searchRef.current as any).focus()
      setIsDropdownOpen(true)
      setNextDropShouldShow(false)
    }
  }, [nextDropShouldShow, optionSelected])

  const onOptionSelected = (e: React.SyntheticEvent, regimen: Regimen) => {
    e?.preventDefault()
    if ((e as any).clientX === 0 && (e as any).clientY === 0) return
    onOptionChanged(regimen)
  }

  const onOptionChanged = (regimen: Regimen) => {
    setIsDropdownOpen(false)
    setNextDropShouldShow(true)
    setFilter("")
    setPreselect(0)
    onSelected(regimen.ID)
  }

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === filter) return
    setFilter(e.target.value)
    setIsDropdownOpen(true)
  }

  const onClickSearch = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    setIsDropdownOpen(true)
  }

  const onClickOptionSelected = (e: React.SyntheticEvent) => {
    e.preventDefault()
    onSelected(0)
    setTimeout(() => {
      setIsDropdownOpen(true)
    }, 1)
  }

  useKeyboard({
    keyCodes: ["Escape"],
    onKeydown: () => {
      setIsDropdownOpen(false)
      setPreselect(0)
    },
  })

  useKeyboard({
    keyCodes: ["ArrowDown"],
    onKeydown: () => {
      setPreselect(preselect + 1)
    },
  })

  useKeyboard({
    keyCodes: ["ArrowUp"],
    onKeydown: () => {
      setPreselect(preselect === 0 ? preselect : preselect - 1)
    },
  })

  useKeyboard({
    keyCodes: ["Enter"],
    onKeydown: () => {
      if (!isDropdownOpen) return
      if (filtered === undefined) return
      onOptionChanged(filtered[preselect])
    },
  })

  useOutboundClick({
    elementRef: dropDownRef,
    onInnerClickSubcription: () => {},
    onOuterClickSubcription: () => {
      optionSelected === 0 && setIsDropdownOpen(false)
    },
  })

  return (
    <div>
      {optionSelected === 0 && (
        <div className="text-left w-full min-w-[300px] max-w-[500px]">
          <div className="flex flex-col relative">
            <input
              ref={searchRef}
              type="text"
              placeholder="Buscar régimen..."
              className="w-full mb-3 px-3 py-2 border border-gray-300 rounded-md"
              value={filter}
              onChange={onChangeSearch}
              onClick={onClickSearch}
            />
            <LookUpIcon />
            <div
              ref={dropDownRef}
              className={`overflow-auto max-h-80 z-10 origin-top-right top-10 right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 ${
                isDropdownOpen ? "block" : "hidden"
              }${float ? " absolute" : ""}`}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {filtered?.map((regimen, index) => (
                <button
                  key={index}
                  onClick={(e: React.SyntheticEvent) =>
                    onOptionSelected(e, regimen)
                  }
                  className={`block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900
                  ${preselect === index ? "bg-gray-100" : ""}`}
                  role="menuitem"
                >
                  <div className="w-full flex items-center justify-between">
                    <RegimenOption sexo={sexoSelected} option={regimen} />
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
      {optionSelected !== 0 && (
        <div
          className={`flex items-center justify-between w-full px-3 py-2 rounded-xl ${
            float ? "border border-gray-400" : ""
          }`}
        >
          <button
            onClick={onClickOptionSelected}
            className={`w-full text-left text-sm text-gray-700`}
            role="menuitem"
          >
            <RegimenOption
              sexo={sexoSelected}
              option={options?.find((r) => r.ID === optionSelected) as Regimen}
            />
          </button>
        </div>
      )}
    </div>
  )
}

export default SelectRegimenAportes
