export const determinationOrder = [
  { key: "empleador", value: "Empleador" },
  { key: "cuit", value: "Cuit" },
  { key: "caja", value: "Caja" },
  { key: "regimen", value: "Actividad" },
  { key: "periodo_inicio", value: "Periodo Inicio" },
  { key: "periodo_cese", value: "Periodo Cese" },
]

export const deudaOrder = [
  "id",
  "Actual",
  "Aportes.1",
  "Aportes.2",
  "Aportes1",
  "Desde",
  "Fonavi",
  "Fonavi.1",
  "Fonavi.2",
  "Hasta",
  "Histórica",
  "INSSJP",
  "INSSJP.1",
  "INSSJP.2",
  "Subtotal",
  "Subtotal.1",
  "Total",
]

export const derechoDirectoOrder = ["regimen", "Años", "Meses", "dias"]

export const determinationSteps: number = 1
