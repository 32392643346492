import { useGetRegimenesQuery } from "../services/dataApi"
import { getQueryParamValue } from "../utils/query"
import useSmoothScroll from "./useSmoothScrolling"

const useAppLoader = ({ param }: { param?: string } = { param: "" }) => {
  useGetRegimenesQuery({})
  useSmoothScroll()
  const queryValue = getQueryParamValue(param)

  return { queryValue }
}

export default useAppLoader
