import Cookies from "js-cookie"

export const setCookie = (name: string, value: string, days: number) => {
  const expires = new Date()
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000)
  Cookies.set(name, value, { expires })
}

export const getCookie = (name: string) => Cookies.get(name)

export const deleteCookie = (name: string) => Cookies.remove(name)
