import { pricingMap } from "../../../layout/Landing/Pricing/PricingMobile"

const MercadoPagoCheckout = ({ selected }: { selected: number }) => {
  const basepath = import.meta.env.VITE_PAYMENT_BACKEND_URL as string
  const item = pricingMap[selected]
  return (
    <div className="">
      <div className="mt-24 mr-auto ml-auto px-8 flex flex-col items-center justify-center text-center">
        <div className="mb-8">
          <h3 className="text-xl font-bold">
            Estás por realizar tu subcripción a Ai Need por el plan:{" "}
          </h3>
        </div>
        <div className="bg-ct-trademark-light w-[300px] py-4 px-2 rounded-b-xl rounded-t-xl flex justify-center items-center flex-col mb-5">
          <div className="px-4 py-2 text-base font-medium text-white bg-blue-600 rounded-full">
            Plan {item.title}
          </div>
          <div className="mt-6 text-6xl lg:text-5xl font-bold">
            {item.price}
          </div>
          <p className="mt-2 font-normal text-gray-500">
            <span className="text-base">Por mes</span>
            <span className="text-2xl">*</span>
          </p>
          <div className="mt-3 font-normal text-gray-500">
            <span className="text-base">Prueba gratuita por 10 días</span>
          </div>
        </div>

        <a
          href={basepath + `/api/v1/payments?quota=empresa`}
          referrerPolicy="origin"
          className="inline-flex items-center font-semibold text-blue-600 hover:text-blue-700"
        >
          Ir a MercadoPago
        </a>
      </div>
    </div>
  )
}

export default MercadoPagoCheckout
