export const getGoogleUrl = (redirectTo: string) => {
  const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`

  const options = {
    redirect_uri: (import.meta.env.VITE_CLIENT_URL as string) + redirectTo,
    client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID as string,
    response_type: "token",
    prompt: "consent",
    scope: ["https://www.googleapis.com/auth/userinfo.email"].join(" "),
    // state: from,
  }

  const qs = new URLSearchParams(options)

  return `${rootUrl}?${qs.toString()}`
}
