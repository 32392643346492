import React, { useEffect, useState } from "react"

const useAutoCounterAnimation = (target: number, duration: number) => {
  const [count, setCount] = useState(0)

  useEffect(() => {
    let startTimestamp: number | null = null
    const step = (timestamp: number) => {
      if (startTimestamp == null) startTimestamp = timestamp
      const progress = Math.min((timestamp - startTimestamp) / duration, 1)
      setCount(Math.floor(progress * target))
      if (progress < 1) {
        window.requestAnimationFrame(step)
      }
    }
    window.requestAnimationFrame(step)
  }, [target, duration])

  return count
}

const AutoCounter: React.FC<{ target: number; duration: number }> = ({
  target,
  duration,
}) => {
  const count = useAutoCounterAnimation(target, duration)

  return <div className="text-4xl">{count}</div>
}

export default AutoCounter
