import {
  faCalculator,
  faDollarSign,
  faHouse,
  faMoneyBill,
  faPowerOff,
  faUser,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"

import { ToastContext } from "../../providers/Toasts/ToastContext"
import Paths from "../../routes/paths"
import { deleteCookie, getCookie } from "../../utils/cookies"
import logoContrast from "./../../assets/images/logo-contrast.png"

const SidebarServices = () => {
  const navigate = useNavigate()
  const { showToast } = useContext(ToastContext)
  const onClickLogout = (e: React.FormEvent) => {
    e.preventDefault()
    deleteCookie("access_token")
    showToast({
      title: "",
      message: "Tu sesión fue cerrada",
      type: "success",
    })
    while (getCookie("access_token") !== undefined) {
      /* empty */
    }
    navigate(Paths.home)
  }
  return (
    <>
      <div className="w-ful h-full bg-[#56575b] flex flex-col justify-between">
        <div className="px-4 py-6">
          <a href={Paths.home} title="" className="flex justify-center">
            <img className="w-auto h-8" src={logoContrast} alt="" />
          </a>

          <ul className="mt-6 space-y-1">
            {/* <li>
          <details className="group [&_summary::-webkit-details-marker]:hidden">
            <summary className="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-white hover:bg-gray-500 hover:text-white">
              <span className="text-sm font-medium"> Soluciones </span>

              <span className="shrink-0 transition duration-300 group-open:-rotate-180">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </summary>

            <ul className="mt-2 space-y-1 px-4">
              <li>
                <a
                  href={Paths.determination}
                  className="block rounded-lg px-4 py-2 text-sm font-medium text-white hover:bg-gray-500 hover:text-white"
                >
                  Cálculo del haber
                </a>
              </li>

              <li>
                <a
                  href={Paths.home}
                  className="block rounded-lg px-4 py-2 text-sm font-medium text-white hover:bg-gray-500 hover:text-white"
                >
                  Determinación del haber
                </a>
              </li>
            </ul>
          </details>
        </li> */}

            <li>
              <a
                href={Paths.account}
                className="block rounded-lg px-4 py-2 text-sm font-medium text-white hover:bg-gray-500 hover:text-white"
              >
                <FontAwesomeIcon
                  icon={faHouse}
                  className="mr-3 text-ct-trademark-secondary w-5"
                />
                Menú
              </a>
            </li>

            <li>
              <a
                href={Paths.account}
                className="block rounded-lg px-4 py-2 text-sm font-medium text-white hover:bg-gray-500 hover:text-white"
              >
                <FontAwesomeIcon
                  icon={faUser}
                  className="mr-3 text-ct-trademark w-5"
                />
                Mis Datos
              </a>
            </li>

            <li>
              <a
                href={Paths.account}
                className="block rounded-lg px-4 py-2 text-sm font-medium text-white hover:bg-gray-500 hover:text-white"
              >
                <FontAwesomeIcon
                  icon={faCalculator}
                  className="mr-3 text-ct-trademark w-5"
                  /* text-blue-300 */
                />
                Mis Cálculos
              </a>
            </li>

            <li>
              <a
                href={Paths.account}
                className="block rounded-lg px-4 py-2 text-sm font-medium text-white hover:bg-gray-500 hover:text-white"
              >
                <FontAwesomeIcon
                  icon={faDollarSign}
                  className="mr-3 text-ct-trademark w-5"
                />
                Plan Mensual
              </a>
            </li>

            <li>
              <a
                href={Paths.account}
                className="block rounded-lg px-4 py-2 text-sm font-medium text-white hover:bg-gray-500 hover:text-white"
              >
                <FontAwesomeIcon
                  icon={faMoneyBill}
                  className="mr-3 text-green-300 w-5"
                />
                Costos y facturación
              </a>
            </li>

            <li>
              <form action="/logout">
                <button
                  type="submit"
                  className="w-full rounded-lg px-4 py-2 text-sm font-medium text-white [text-align:_inherit] hover:bg-gray-500 hover:text-white"
                  onClick={onClickLogout}
                >
                  <FontAwesomeIcon
                    icon={faPowerOff}
                    className="mr-3 text-red-300 w-5"
                  />
                  Cerrar sesión
                </button>
              </form>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}
export default SidebarServices
