import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"

import useBreakpoint, { Breakpoints } from "../../../hooks/useBreakpoint"
import useKeyboard from "../../../hooks/useKeyboard"
import Button from "../buttons/Button"

interface ModalProps {
  header: string
  onClose: () => void
  children: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({ header, onClose, children }) => {
  const [id, setId] = useState("")

  useEffect(() => {
    setId("modal-" + Math.random().toString(36).substring(2, 9))
  }, [])

  useKeyboard({
    keyCodes: ["Escape"],
    onKeydown: () => {
      onClose()
    },
  })

  const breakpoint = useBreakpoint()

  return (
    <div
      id={id}
      tabIndex={-1}
      aria-hidden="true"
      className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-40 justify-center items-center w-full md:inset-0 h-modal md:h-full"
    >
      <div
        className="relative p-4 w-full max-w-2xl h-full md:h-auto"
        style={
          breakpoint <= Breakpoints.sm
            ? {}
            : { transform: "translate(-50%, -50%)", left: "50%", top: "30%" }
        }
      >
        <div className="relative p-4 bg-gray-200 rounded-lg shadow sm:p-5">
          <div className="flex justify-between items-center mb-4 rounded-t border-b sm:mb-5">
            <h3 className="text-lg font-semibold text-gray-900">{header}</h3>
            <Button
              color={"default"}
              size={"sm"}
              outline={false}
              onClick={onClose}
            >
              <span className="sr-only">Close modal</span>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
