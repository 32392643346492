export const convertSnakeCaseToTitleCase = (str: string) => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

export const formatToLocaleDate = (dateString: string) => {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" }
  return new Date(dateString).toLocaleDateString("es-AR", options as any)
}

/**
 * Format Spanish Text, replacing "/s" to "s" and "/es" to "es" when needed, and emptying when count is less than 0
 * @param count
 * @param text
 * @returns
 */
export const formatST = (count: number, text: string): string => {
  let formattedText = text
  if (count > 1) {
    formattedText = formattedText.replace(/\/s/g, "s")
    formattedText = formattedText.replace(/\/es/g, "es")
  } else {
    formattedText = formattedText.replace(/\/s/g, "")
    formattedText = formattedText.replace(/\/es/g, "")
  }
  return formattedText
}

export const addOneDay = (date: Date) => {
  const nextDay = new Date(date)
  nextDay.setDate(nextDay.getDate() + 1)
  return nextDay
}
