import React, { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { Spinner } from "../components/common/spinner/Spinner"
import useAppLoader from "../hooks/useAppLoader"
import { ToastContext } from "../providers/Toasts/ToastContext"
import Paths from "../routes/paths"

const CheckoutPage = () => {
  const { queryValue: result } = useAppLoader({ param: "result" })
  const { showToast } = useContext(ToastContext)
  const navigate = useNavigate()
  useEffect(() => {
    if (result === "success")
      showToast({
        title: "Éxito!",
        message: "Tu subcripción en mercado pago se acreditó con éxito",
        type: "success",
      })
    else
      showToast({
        title: "Error al procesar el pago de subcripción en mercadopago",
        message:
          "No te preocupes. Estaremos trabajando para acreditar tu subcripción",
        type: "error",
      })
    setTimeout(() => {
      navigate(Paths.determination)
    }, 2000)
  }, [])

  return (
    <>
      <div className="h-full flex justify-center items-center">
        <Spinner align="center" />
      </div>
    </>
  )
}

export default CheckoutPage
