import React from "react"

import logoContrast from "./../assets/images/logo-contrast.png"
import pageIcon from "./../assets/images/page-icon.png"

const ErrorPage = () => {
  return (
    <div className="bg-gray-200 flex items-center justify-center h-screen">
      <div className="bg-white p-8 rounded-b-xl rounded-t-xl shadow text-center">
        <img
          src={logoContrast}
          alt="logo"
          className="mb-5 w-[150px] inline-block"
        />
        <h1 className="text-2xl font-bold mb-6">Oops! Ha ocurrido un error</h1>
        <p className="mb-6">
          Vuelve a la página principal y vuelve a intentarlo
        </p>
        <a
          href="/"
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          <img src={pageIcon} alt="logo" className="mr-3 w-5 inline-block" />
          Volver
        </a>
      </div>
    </div>
  )
}

export default ErrorPage
