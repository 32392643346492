import React, { useEffect } from "react"

import useBreakpoint, { Breakpoints } from "../hooks/useBreakpoint"

const TermsConditionsPage = () => {
  const breakpoint = useBreakpoint()
  window.scrollTo(0, 0)

  useEffect(() => {
    document.title = "Términos y condiciones - Ai Need"
  }, [])

  const showEmail = (
    <span style={{ fontFamily: "monospace", fontWeight: "bold" }}>
      @aineed.com.ar
    </span>
  )

  return (
    <div className="w-full flex flex-col items-center pt-12 pb-24">
      <div
        className={`mt-2 mb-8 text-justify w-full ${
          breakpoint >= Breakpoints.lg ? "px-10" : "px-5"
        }`}
      >
        <h4 className="px-2 text-xl font-bold text-navy-700 text-center mb-5">
          Términos y condiciones
        </h4>

        <p className="mt-2 px-2 text-base text-gray-600">
          Somos una empresa argentina de servicios de consultoría en
          contabilidad, especializada en cálculos de jubilaciones, con 2 años de
          trayectoria en el área (en adelante, &quot;la Empresa&quot;). Al
          acceder y utilizar este sitio web, usted acepta los siguientes
          términos y condiciones:
        </p>

        <h2 className="mt-2 px-2 text-base text-gray-600">
          1. Uso del Sitio Web
        </h2>
        <p className="mt-2 px-2 text-base text-gray-600">
          La información y los servicios ofrecidos en este sitio web tienen
          fines informativos, previsionales y contables. Los servicios brindados
          constituyen asesoría contable y legal. Usted es responsable de
          verificar la exactitud, vigencia y aplicabilidad de la información y
          los servicios a su situación particular, así como de proporcionar
          datos verdaderos y válidos. La Empresa se basa en la información
          suministrada por usted para brindar sus servicios de manera adecuada.
          No utilizaremos la información que nos proporcione para fines
          comerciales. Tampoco utilizaremos su correo electrónico para enviarle
          ofertas, descuentos ni cupones. Nuestros servicios y precios son los
          publicados. Si usted posee un cupón de descuento, no será válido.
        </p>

        <h2 className="mt-2 px-2 text-base text-gray-600">
          2. Limitación de Responsabilidad
        </h2>
        <p className="mt-2 px-2 text-base text-gray-600">
          La Empresa no será responsable por cualquier daño directo, indirecto,
          incidental, consecuente o especial que resulte del uso de este sitio
          web o de la aplicación. El uso de este sitio web y de la aplicación es
          bajo su propio riesgo. La Empresa no garantiza que el sitio web o la
          aplicación estén libres de errores o interrupciones. Para evitar el
          phishing, todas las comunicaciones oficiales de la Empresa se enviarán
          desde direcciones de email con el dominio {showEmail}. Asegúrese de
          que puede recibir correos de este dominio en su casilla de correo.
        </p>

        <h2 className="mt-2 px-2 text-base text-gray-600">
          3. Modificaciones del Sitio Web
        </h2>
        <p className="mt-2 px-2 text-base text-gray-600">
          La Empresa se reserva el derecho de modificar, suspender o interrumpir
          el acceso al sitio web, o a parte del mismo, en cualquier momento y
          sin previo aviso, por motivos técnicos, de seguridad, legales o de
          otra índole. Cualquier comunicación oficial de carácter sea urgente o
          importante se le enviará por email, y usted deberá asegurarse de poder
          recibir correos provenientes de {showEmail} en su casilla de correo.
          Queda usted debidamente comunicado archivado y publicado.
        </p>

        <h2 className="mt-2 px-2 text-base text-gray-600">
          4. Propiedad Intelectual
        </h2>
        <p className="mt-2 px-2 text-base text-gray-600">
          El contenido de este sitio web está protegido por las leyes de
          propiedad intelectual y pertenece a la Empresa o a sus licenciantes.
          Usted puede consultar, descargar e imprimir el contenido para su uso
          personal y no comercial, siempre que cite la fuente y no altere ni
          suprima los derechos de autor u otros avisos legales. Queda prohibido
          reproducir, distribuir, comunicar públicamente, transformar o utilizar
          el contenido de este sitio web para fines comerciales sin la
          autorización expresa y por escrito de la Empresa.
        </p>

        <h2 className="mt-2 px-2 text-base text-gray-600">
          5. Enlaces a Sitios de Terceros
        </h2>
        <p className="mt-2 px-2 text-base text-gray-600">
          Este sitio web puede contener enlaces a otros sitios web de terceros,
          que no están bajo el control ni la supervisión de la Empresa. No nos
          hacemos responsables del contenido, calidad, seguridad, legalidad o
          veracidad de dichos sitios web, ni de las prácticas de privacidad o de
          protección de datos que puedan aplicar. El acceso y el uso de estos
          sitios web es bajo su propio riesgo y responsabilidad.
        </p>

        <h2 className="mt-2 px-2 text-base text-gray-600">
          6. Legislación Aplicable y Jurisdicción
        </h2>
        <p className="mt-2 px-2 text-base text-gray-600">
          Estos términos y condiciones se rigen por la legislación argentina y
          se someten a la jurisdicción de los tribunales competentes de la
          República Argentina.
        </p>
      </div>
    </div>
  )
}

export default TermsConditionsPage
