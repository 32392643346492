import React, { useContext, useRef } from "react"
import { MagicMotion } from "react-magic-motion"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

import { determinationSteps } from "../../../constants/base"
import {
  SingleFileImportRequest,
  UserDeterminationCalculusRequest,
} from "../../../domain/dtos"
import useDownloadFile from "../../../hooks/useDownloadFile"
import useStepper from "../../../hooks/useStepper"
import { ToastContext } from "../../../providers/Toasts/ToastContext"
import { useAppDispatch } from "../../../redux/config/hooks"
import { determinationSlice } from "../../../redux/reducers/determinationSlice"
import Paths from "../../../routes/paths"
import { useGetRegimenesQuery } from "../../../services/dataApi"
import { useCalculateDeterminationMutation } from "../../../services/determinationApi"
import { useDeterminationService } from "../../../services/determinationService"
import { isBirthDateOlderThan } from "../../../utils/validations"
import Button from "../../common/buttons/Button"
import { Spinner } from "../../common/spinner/Spinner"
import DeterminationResult from "../DeterminationResult/DeterminationResult"
import DeterminationForm from "./DeterminationForm"
import { getTipoCalculoVars } from "./DeterminationUtils"

const DeterminationCnt: React.FC = () => {
  const location = useLocation()
  const { showToast } = useContext(ToastContext)
  const [
    calculateDetermination,
    { isLoading: isLoadingCalculation, data: calculationData },
  ] = useCalculateDeterminationMutation()

  const {
    uploadDetermination,
    isLoading: isLoadingUpload,
    data: importedData,
  } = useDeterminationService()

  const { data: regimenesFromDB, isLoading: isLoadingRegimenes } =
    useGetRegimenesQuery({})

  const submitRef = useRef(null)
  const isLoading =
    isLoadingUpload || isLoadingCalculation || isLoadingRegimenes

  const determinationData = useSelector(
    (state) => (state as any)?.determinationData,
  )
  const dispatchLocalSubmit = useAppDispatch()

  const { currentStep, increaseStep, decreaseStep } = useStepper({
    maxValue: determinationSteps,
  })

  const tipoCalculo: number = location.pathname.includes(Paths.dead_pension)
    ? 3
    : location.pathname.includes(Paths.invalid_pension)
    ? 2
    : 1

  const { isDet, isInvalidPension } = getTipoCalculoVars(tipoCalculo)

  const onHistoriaLaboralImport = async (file: File) => {
    const dto: SingleFileImportRequest = {
      cuit: importedData?.cuit,
      apellido: importedData?.apellido,
      historia_laboral: file,
      sicam_previous: importedData?.sicam,
    }
    await uploadDetermination(dto)
  }

  const onSicamImport = async (file: File) => {
    const dto: SingleFileImportRequest = {
      cuit: importedData?.cuit,
      apellido: importedData?.apellido,
      sicam: file,
      historia_laboral_previous: importedData?.historia_laboral,
    }
    await uploadDetermination(dto)
  }

  const onCalculateSubmit = async (
    request: UserDeterminationCalculusRequest,
  ) => {
    try {
      if (!isBirthDateOlderThan(request.fecha_nacimiento, 16)) {
        showToast({
          title: "Debes ingresar una fecha de nacimiento de un adulto",
          message: "Verifica los datos ingresados y vuelve a intentar",
          type: "error",
        })
        return
      }
      await calculateDetermination(request).unwrap()
      showToast({
        title: "Calculo exitoso!",
        message: "Descargá el archivo generado",
        type: "success",
      })
      dispatchLocalSubmit(determinationSlice.actions.updateFormData(request))
      increaseStep()
    } catch (error) {
      showToast({
        title: "Error en el cálculo!",
        message: "Verifica los datos ingresados y vuelve a intentar",
        type: "error",
      })
    }
  }

  const onSubmit = async (request: any) => {
    if (currentStep === 0) await onCalculateSubmit(request)
    // else if (currentStep === 1) await onCalculateSubmit(request)
  }

  const onStepBack = () => {
    decreaseStep()
  }

  const onStepForward = () => {
    if (currentStep === determinationSteps) openDocument()
    else if (submitRef?.current !== null) {
      ;(submitRef.current as HTMLElement).click()
    }
  }

  const { openDocument } = useDownloadFile({
    base64Data: calculationData,
    filename: `aineed_informe_${
      isDet
        ? "determinacion_derecho"
        : isInvalidPension
        ? "pension_invalidez"
        : "pension_fallecimiento"
    }_${determinationData.cuit as string}`,
  })

  // const views: { [key: number]: React.ComponentType<any> } = {
  // 0: ImportData,
  // 1: DeudaAportesCnt,
  // 1: HistoricalTableCnt,
  // 2: DeterminationResult,
  // 0: HistoricalTableCnt,
  // 1: DeterminationResult,
  // }

  // const CurrentView = views[currentStep]

  const Buttons = () => (
    <div className="flex items-center justify-center">
      {currentStep !== 0 && (
        <Button
          color={"danger"}
          size={"sm"}
          outline={false}
          className="ml-5"
          onClick={() => onStepBack()}
        >
          Atrás
        </Button>
      )}
      <Button
        color={currentStep === determinationSteps ? "success" : "primary"}
        size={"sm"}
        outline={false}
        className="ml-5"
        onClick={() => onStepForward()}
      >
        {currentStep < determinationSteps && <>Calcular</>}
        {currentStep === determinationSteps && <>Descargar</>}
      </Button>
    </div>
  )

  const tableStep = 0
  // const breakpoint = useBreakpoint()

  return (
    <MagicMotion>
      <div
        className={`h-full w-full flex flex-col justify-center items-center my-0 mx-auto py-5 pt-5`}
        key="exclude"
      >
        {currentStep !== tableStep && (
          <div className={`flex flex-col justify-center items-center`}>
            <DeterminationResult tipoCalculo={tipoCalculo} />
            <div className={`w-full mt-5`}>
              {isLoading && <Spinner align="center" />}
              {!isLoading && <Buttons />}
            </div>
          </div>
        )}
        {currentStep === tableStep && (
          <div className={`w-full`} key="exclude">
            <div className={`w-full py-3 px-5`}>
              <DeterminationForm
                tipoCalculo={tipoCalculo}
                previousData={determinationData}
                importedData={importedData}
                regimenesFromDB={regimenesFromDB}
                submitRef={submitRef}
                onSubmit={onSubmit}
                onHistoriaLaboralImport={onHistoriaLaboralImport}
                onSicamImport={onSicamImport}
              />
            </div>
            <div className={`w-full mt-5`}>
              {isLoading && <Spinner align="center" />}
              {!isLoading && <Buttons />}
            </div>
          </div>
        )}
      </div>
    </MagicMotion>
  )
}

export default DeterminationCnt
