import { faPlus, faQuestion, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns"
import { useCallback } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useFieldArray } from "react-hook-form"

import { determinationOrder } from "../../../constants/base"
import { HistoriaLaboral } from "../../../domain/entities"
import { useGetRegimenesQuery } from "../../../services/dataApi"
import { useDeterminationService } from "../../../services/determinationService"
import { addOneDay } from "../../../utils/transform-utils"
import Button from "../../common/buttons/Button"
import DraggableRow from "../../common/draggable/DraggableRow"
import Popover from "../../common/popover/Popover"
import SelectRegimenAportes from "../Regimenes/SelectRegimenAportes"

const HistoricalTable = ({
  control,
  register,
  setValue,
  watch,
  sexoSelected,
}: {
  control: any
  register: any
  setValue: any
  watch: any
  sexoSelected: string
}) => {
  const { data: regimenesFromDB } = useGetRegimenesQuery({})
  const today = format(new Date(), "yyyy-MM-dd")
  const defaultRegimen: number = 3
  const newRow: HistoriaLaboral = {
    cuit: "",
    caja: "",
    empleador: "",
    periodo_cese: today,
    periodo_inicio: today,
    regimen: defaultRegimen,
  }

  const mainkey = "historia_laboral"

  const { insert, remove, move } = useFieldArray({
    control,
    name: mainkey,
  })

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      move(dragIndex, hoverIndex)
    },
    [move],
  )

  const handleAddNew = (e?: React.FormEvent) => {
    e?.preventDefault()
    // insert(fields.length, [newRow])
    setValue(`${mainkey}.${fields.length as number}`, newRow)
  }

  const handleAddNewFromExisting = (rowIndex: number) => {
    let current
    if (fields[rowIndex].periodo_cese?.includes("-") === false) {
      current = new Date()
    } else {
      current = fields[rowIndex].periodo_cese.split("-")
    }
    const nextDay = addOneDay(current)
    insert(rowIndex + 1, [
      {
        cuit: fields[rowIndex].cuit,
        caja: fields[rowIndex].caja,
        empleador: fields[rowIndex].empleador,
        periodo_inicio: format(nextDay, "yyyy-MM-dd"),
        periodo_cese: format(nextDay, "yyyy-MM-dd"),
        regimen: fields[rowIndex].regimen,
      },
    ])
  }

  const { resetState } = useDeterminationService()

  const handleEraseTable = async (e?: React.FormEvent) => {
    e?.preventDefault()
    resetState()
  }

  const onChangeRegimen = (id: number, rowIndex: number) => {
    setValue(`${mainkey}.${rowIndex}.regimen`, id)
  }

  const fields = watch(mainkey)

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 overflow-auto">
      <div className="flex justify-between mb-5">
        <div className="flex items-center">
          <h6 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-black">
            Historia Laboral
          </h6>
          <Popover
            onClick={() => {}}
            popoverContent={
              "Puede optar por importar la Historia laboral de MiAnses o el SICAM. La tabla aparecerá aquí y podrá realizar los cambios que desee"
            }
            direction="bottom"
            buttonRender={
              <span className="ml-5 inline-block rounded-full bg-white border-2 border-gray-300 py-[2px] px-[6px] hover:bg-gray-300">
                <FontAwesomeIcon icon={faQuestion} size="xs" color="black" />
              </span>
            }
          />
          <Button
            color={"primary"}
            size={"sm"}
            className={"ml-10"}
            outline={false}
            onClick={handleAddNew}
          >
            Agregar período
          </Button>
        </div>
        <div className="flex justify-end">
          <Button
            color={"danger"}
            size={"sm"}
            className={"ml-10"}
            outline={false}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleEraseTable}
          >
            Borrar tabla e importación
          </Button>
        </div>
      </div>
      <DndProvider backend={HTML5Backend}>
        <table className="table-auto divide-y-2 divide-gray-200 bg-white text-sm font-normal text-base">
          <thead className="ltr:text-left rtl:text-right">
            <tr>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-left">
                Nº
              </th>
              {Object.values(determinationOrder).map((order, index) => (
                <th
                  key={index}
                  className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-left"
                >
                  {order.value}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {fields.map((row: any, rowIndex: number) => (
              <DraggableRow key={rowIndex} id={rowIndex} onMoveRow={moveRow}>
                <td className="whitespace-nowrap px-2 py-1 text-gray-700 text-center">
                  {rowIndex + 1}
                </td>
                {Object.values(determinationOrder).map((order, columnIndex) => {
                  const columnKey = order.key
                  const columnValue = row[columnKey]
                  return (
                    <td
                      key={columnIndex}
                      className="whitespace-nowrap px-4 py-2 text-gray-700"
                    >
                      {columnKey === "periodo_cese" ||
                      columnKey === "periodo_inicio" ? (
                        <input
                          {...register(`${mainkey}.${rowIndex}.${columnKey}`)}
                          type="date"
                          defaultValue={columnValue}
                          required
                        />
                      ) : columnKey === "regimen" ? (
                        <>
                          <SelectRegimenAportes
                            options={regimenesFromDB}
                            sexoSelected={sexoSelected}
                            float={false}
                            optionSelected={columnValue}
                            onSelected={(id: number) =>
                              onChangeRegimen(id, rowIndex)
                            }
                          />
                        </>
                      ) : (
                        <input
                          {...register(`${mainkey}.${rowIndex}.${columnKey}`)}
                          defaultValue={columnValue}
                          required={
                            columnKey !== "cuit" && columnKey !== "empleador"
                          }
                          placeholder={
                            columnKey === "cuit" || columnKey === "empleador"
                              ? "(opcional)"
                              : ""
                          }
                          className={`${
                            columnKey === "caja"
                              ? "w-20"
                              : columnKey === "empleador"
                              ? "w-36"
                              : "w-24"
                          } text-sm`}
                        />
                      )}
                    </td>
                  )
                })}
                <td className="whitespace-nowrap px-4 py-2">
                  <Popover
                    onClick={() => handleAddNewFromExisting(rowIndex)}
                    popoverContent={"Agregar nuevo período"}
                    direction="left"
                    buttonRender={
                      <span className="mr-3 inline-block rounded bg-blue-400 px-4 py-2 text-xs font-medium text-white hover:bg-blue-700">
                        <FontAwesomeIcon icon={faPlus} size="sm" />
                      </span>
                    }
                  ></Popover>
                  <Popover
                    onClick={() => remove(rowIndex)}
                    popoverContent={"Eliminar período"}
                    direction="left"
                    buttonRender={
                      <span className="inline-block rounded bg-red-400 px-4 py-2 text-xs font-medium text-white hover:bg-red-700">
                        <FontAwesomeIcon icon={faTrash} size="sm" />
                      </span>
                    }
                  ></Popover>
                </td>
              </DraggableRow>
            ))}
          </tbody>
        </table>
      </DndProvider>
    </div>
  )
}

export default HistoricalTable
