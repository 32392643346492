const isEmptyArray = (arr: any) => Array.isArray(arr) && arr.length === 0
const isEmptyObject = (obj: any) =>
  Object.getPrototypeOf(obj) === Object.prototype &&
  Object.keys(obj).length === 0

export const isNullOrEmpty = (val: any) => {
  return (
    val === null ||
    val === "" ||
    val === undefined ||
    isEmptyArray(val) ||
    isEmptyObject(val)
  )
}

export const isBirthDateOlderThan = (
  birthDate: string,
  mustAge: number,
): boolean => {
  const currentDate = new Date()
  const birthDateObj = new Date(birthDate)
  let age = currentDate.getFullYear() - birthDateObj.getFullYear()
  const month = currentDate.getMonth() - birthDateObj.getMonth()

  if (
    month < 0 ||
    (month === 0 && currentDate.getDate() < birthDateObj.getDate())
  ) {
    age--
  }

  return age >= mustAge
}
