import { Regimen } from "../../../domain/entities"
import Popover from "../../common/popover/Popover"

const RegimenOption = ({ sexo, option }: { sexo: string; option: Regimen }) => {
  return (
    <div className="flex">
      <span className="mr-1" style={{ overflowWrap: "anywhere" }}>
        {option?.nombre}
      </span>
      {option !== undefined && (
        <div className="flex">
          <Popover
            onClick={() => {}}
            popoverContent={"Edad mínima de aportes"}
            direction="left"
            buttonRender={
              <span className="text-white font-medium bg-sky-500 rounded-lg border-2 border-gray-700 p-[2px] mr-1">
                {sexo === "H" ? option.edadh : option.edadm}
              </span>
            }
          />
          <Popover
            onClick={() => {}}
            popoverContent={"Cantidad mínima de aportes"}
            direction="right"
            buttonRender={
              <span className="text-white font-medium bg-yellow-500 rounded-lg border-2 border-gray-700 p-[2px]">
                {sexo === "H" ? option.aportesh : option.aportesm}
              </span>
            }
          />
        </div>
      )}
    </div>
  )
}

export default RegimenOption
