import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { Regimen } from "../domain/entities"

const baseUrl = (import.meta.env.VITE_BACKEND_URL as string) + "/api/v1/data"

const dataApi = createApi({
  reducerPath: "data",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getRegimenes: builder.query<Regimen[], any>({
      query: () => `/regimenes`,
      transformResponse: (response: Regimen[]) =>
        response.filter((r) => r.buscador === "si"),
    }),
  }),
})

export const { useGetRegimenesQuery } = dataApi
export default dataApi
