import {
  faBarsStaggered,
  faDollarSign,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import Paths from "../../routes/paths"
import logoContrast from "./../../assets/images/logo-contrast.png"

const SidebarLanding = () => (
  <div className="fixed w-[230px]">
    <div className="flex h-screen flex-col justify-between border-e bg-white">
      <div className="px-4 py-6">
        <a href={Paths.home} title="" className="flex">
          <img className="w-auto h-8" src={logoContrast} alt="" />
        </a>

        <ul className="mt-6 space-y-1">
          <li>
            <a
              href="#nosotros"
              className="block rounded-lg bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-500 hover:text-white"
            >
              <FontAwesomeIcon
                icon={faPeopleGroup}
                className="mr-3 text-ct-trademark w-5"
              />
              Nosotros
            </a>
          </li>

          <li>
            <a
              href="#pricing"
              title=""
              className="block rounded-lg bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-500 hover:text-white"
            >
              <FontAwesomeIcon
                icon={faDollarSign}
                className="mr-3 text-ct-trademark w-5"
              />
              Planes{" "}
            </a>
          </li>

          <li>
            <a
              href={Paths.determination}
              className="block rounded-lg bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-500 hover:text-white"
            >
              <FontAwesomeIcon
                icon={faBarsStaggered}
                className="mr-3 text-ct-trademark w-5"
              />
              Soluciones
            </a>
          </li>

          <li>
            <a
              href={Paths.login}
              className="block rounded-lg bg-transparent px-4 py-2 text-sm font-bold text-gray-800 text-center hover:bg-gray-200"
            >
              {" "}
              Iniciar sesión{" "}
            </a>
          </li>
          <li>
            <a
              href={Paths.register}
              className="block rounded-lg bg-blue-100 px-4 py-2 text-sm font-bold text-black text-center hover:bg-gray-500 hover:text-white"
              role="button"
            >
              {" "}
              Prueba gratuita{" "}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
)

export default SidebarLanding
