import { faGavel, faGlobe } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import useAppLoader from "../hooks/useAppLoader"
import AutoCounter from "../hooks/useAutoCounterAnimation"
import PricingSection from "../layout/Landing/Pricing/PricingSection"
import Paths from "../routes/paths"
import argentinaIcon from "./../assets/images/argentina-icon.png"
import curvedLines from "./../assets/images/curved-lines.png"
import curvedLines2 from "./../assets/images/curved-lines-2.png"
import aboutCover from "./../assets/images/landing-about.jpg"
import cover from "./../assets/images/landing-cover.jpg"
import logo from "./../assets/images/logo.png"
import mapa from "./../assets/images/mapa-argentina.jpg"

const HomePage = () => {
  useAppLoader()
  return (
    <>
      <section id="main" className="bg-yellow-50">
        <div className="flex flex-col lg:flex-row lg:items-stretch lg:min-h-[800px]">
          <div className="relative flex items-center justify-center w-full lg:order-2 lg:w-7/12">
            <div className="absolute bottom-0 right-0 hidden lg:block">
              <img
                className="object-contain w-auto h-48"
                src={curvedLines}
                alt=""
              />
            </div>

            <div className="relative px-4 pt-12 pb-16 text-center sm:px-6 md:px-24 2xl:px-32 lg:py-24 lg:text-left">
              <a
                href={Paths.home}
                title=""
                className="flex justify-center items-center mb-10"
              >
                <img className="w-auto h-16" src={logo} alt="" />
              </a>
              <h1 className="text-4xl font-bold text-black sm:text-6xl xl:text-8xl">
                Calculá <br />
                tu Jubilación
              </h1>
              <p className="mt-8 text-xl text-black">
                Con Ai Need podrás calcular tu jubilación, cuántos años te
                faltan y qué haber te corresponde. <br />
                No esperes a que tu jubilación por Anses sea escasa, no
                entregues tu plan de retiro. <br />
                <span className="text-md">
                  <b>Asegurá</b> tu futuro.
                  <br />
                  <b>Protegé</b> tu carrera.
                </span>
              </p>

              <a
                href={Paths.register}
                className="inline-flex items-center px-6 py-5 text-base font-semibold text-black transition-all duration-200
                    bg-ct-trademark mt-9 hover:bg-ct-trademark-dark rounded-lg"
                role="button"
              >
                {" "}
                Calcular{" "}
              </a>
            </div>

            {/* <div className="absolute right-0 z-10 -bottom-16 lg:top-24 lg:-left-20">
            <img
              className="w-32 h-32 md:w-40 md:h-40"
              src="https://cdn.rareblocks.xyz/collection/celebration/images/hero/3/circular-text.png"
              alt=""
            />
          </div> */}
          </div>

          <div className="relative w-full overflow-hidden lg:order-1 h-96 lg:h-auto lg:w-5/12">
            <div className="absolute inset-0">
              <img
                className="object-cover w-full h-full scale-150"
                src={cover}
                alt=""
              />
            </div>

            <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>

            <div className="absolute bottom-0 left-0">
              <div className="p-4 sm:p-6 lg:p-8">
                <div className="flex items-end">
                  <img
                    className="object-contain w-auto h-12"
                    src={argentinaIcon}
                    alt=""
                  />
                  <h2 className="font-bold text-white text-7xl ml-2.5">
                    <AutoCounter target={395} duration={1500} />
                  </h2>
                </div>
                <p className="max-w-xs mt-1.5 text-xl text-white">
                  Argentinos han garantizado su futuro gracias a
                  <img className="object-cover w-auto h-6" src={logo} alt="" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="nosotros" className="bg-ct-trademark-light">
        <div className="flex flex-col lg:flex-row lg:items-stretch lg:min-h-[800px]">
          <div className="relative flex items-center justify-center w-full lg:order-2 lg:w-7/12">
            <div className="absolute bottom-0 left-0 hidden lg:block transform -scale-x-100">
              <img
                className="object-contain w-auto h-48 text-ct-trademark-dark"
                src={curvedLines2}
                alt=""
              />
            </div>

            <div className="relative px-4 pt-24 pb-16 text-center sm:px-6 md:px-24 2xl:px-32 lg:py-24 lg:text-left">
              <h1 className="text-4xl font-bold text-black sm:text-6xl xl:text-8xl">
                Nosotros
              </h1>
              <p className="mt-8 text-l text-black">
                Somos una firma Argentina, autónoma, experta en cálculos
                jubilatorios con una trayectoria que abarca la totalidad del
                territorio nacional. Utilizamos tecnologías de vanguardia e
                inteligencia artifical para facilitar tu trabajo y mejorar tu
                rendimiento laboral. <br /> <br />
                <b>
                  Nuestra misión es ofrecer un servicio de Consultoría
                  Previsional, a un menor costo, más rápido y seguro.
                </b>
              </p>
            </div>
          </div>

          <div className="relative w-full overflow-hidden lg:order-2 h-96 lg:h-auto lg:w-5/12">
            <div className="absolute inset-0">
              <img
                className="object-cover w-full h-full"
                src={aboutCover}
                alt=""
              />
            </div>

            <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>

            <div className="absolute bottom-0 left-0">
              <div className="p-4 sm:p-6 lg:p-8">
                <div className="flex items-center">
                  <FontAwesomeIcon
                    icon={faGavel}
                    size="xl"
                    color="white"
                    className=""
                  />
                  <h2 className="font-bold text-white text-7xl ml-2.5">
                    <AutoCounter target={15} duration={1500} />
                  </h2>
                </div>
                <p className="max-w-xs mt-1.5 text-xl text-white">
                  Firmas de abogados nos respaldan
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="results" className="bg-yellow-50">
        <div className="flex flex-col lg:flex-row lg:items-stretch lg:min-h-[800px]">
          <div className="relative flex items-center justify-center w-full lg:order-2 lg:w-7/12">
            <div className="absolute bottom-0 right-0 hidden lg:block">
              <img
                className="object-contain w-auto h-48"
                src={curvedLines}
                alt=""
              />
            </div>

            <div className="relative px-4 pt-12 pb-16 text-center sm:px-6 md:px-24 2xl:px-32 lg:py-24 lg:text-left">
              {/* <a
                href={Paths.home}
                title=""
                className="flex justify-center items-center mb-10"
              >
                <img className="w-auto h-16" src={logo} alt="" />
              </a> */}
              <h3 className="text-4xl font-bold text-black sm:text-6xl xl:text-6xl">
                Respaldo
              </h3>
              <p className="mt-8 text-xl text-black">
                Ai Need cuenta con un sistema inteligente de cálculo basados en{" "}
                <span className="text-ct-trademark text-lg">
                  inteligencia artificial
                </span>
                , nuestros programas nos aseguran 100% de efectividad en los
                cálculos. <br />
                Sin intermediarios, sin acción humana que pueda provocar errores
                en los cálculos. <br />
              </p>
            </div>

            {/* <div className="absolute right-0 z-10 -bottom-16 lg:top-24 lg:-left-20">
            <img
              className="w-32 h-32 md:w-40 md:h-40"
              src="https://cdn.rareblocks.xyz/collection/celebration/images/hero/3/circular-text.png"
              alt=""
            />
          </div> */}
          </div>

          <div className="relative w-full lg:order-1 h-96 lg:h-auto lg:w-5/12">
            <div className="absolute inset-0">
              <img className="object-cover w-full h-full" src={mapa} alt="" />
            </div>

            <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>

            <div className="absolute bottom-0 left-0">
              <div className="p-4 sm:p-6 lg:p-8">
                <div className="flex items-center">
                  <FontAwesomeIcon icon={faGlobe} className="text-white" />
                  {/* <h2 className="font-bold text-white text-7xl ml-2.5">395</h2> */}
                  <span className="max-w-xs ml-3 text-xl text-white">
                    Cobertura Nacional
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PricingSection />
    </>
  )
}

export default HomePage
