import React, { useEffect, useState } from "react"

import Paths from "../../routes/paths"
import { useGetUserQuery } from "../../services/sessionsApi"
import { getCookie } from "../../utils/cookies"
import { isNullOrEmpty } from "../../utils/validations"
import { Spinner } from "../common/spinner/Spinner"

const AccountCnt = () => {
  const [name, setName] = useState("")
  const cookie = getCookie("access_token") as string
  const { data, isFetching } = useGetUserQuery(cookie)

  useEffect(() => {
    if (!isNullOrEmpty(data)) {
      setName(data.name)
    }
  }, [data])

  return (
    <>
      {isFetching && <Spinner align="center"></Spinner>}
      {!isFetching && (
        <div className="px-16 py-8">
          <div className="">
            <span className="mb-16 text-xl font-bold tracking-tight text-gray-900">
              {name !== "" && <>Hola, {name}</>}
              {name === "" && <>Hola!</>}
            </span>
            {/* <span className="text-xl tracking-tight text-gray-900">{name}</span> */}
          </div>
          <div className="flex items-center justify-center">
            <a
              className="mr-5 py-4 px-4 font-medium border-b border-gray-200 text-white bg-gray-900 border-b border-white/20
        rounded-t-xl rounded-b-xl hover:bg-gray-200 hover:text-gray-900 transition-colors duration-500 ease-in-out"
              href={Paths.determination}
            >
              Calcular determinación
            </a>
            <a
              className="mr-5 py-4 px-4 font-medium border-b border-gray-200 text-white bg-gray-900 border-b border-white/20
        rounded-t-xl rounded-b-xl hover:bg-gray-200 hover:text-gray-900 transition-colors duration-500 ease-in-out"
              href={Paths.invalid_pension}
            >
              Calcular pensión por invalidez
            </a>
            <a
              className="mr-5 py-4 px-4 font-medium border-b border-gray-200 text-white bg-gray-900 border-b border-white/20
        rounded-t-xl rounded-b-xl hover:bg-gray-200 hover:text-gray-900 transition-colors duration-500 ease-in-out"
              href={Paths.dead_pension}
            >
              Calcular pensión por fallecimiento
            </a>
          </div>
        </div>
      )}
    </>
  )
}

export default AccountCnt
