import { XYCoord } from "dnd-core"
import { FC, ReactNode, useRef } from "react"
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd"

interface IDragItem {
  index: number
  id: string
  type: string
}

interface DraggableRowProps {
  id: string
  onMoveRow: (dragIndex: number, hoverIndex: number) => void
}

const DraggableRow: FC<DraggableRowProps | any> = ({
  id,
  onMoveRow,
  children,
}: {
  id: number
  onMoveRow: Function
  children: ReactNode
}) => {
  const ref = useRef<HTMLTableRowElement>(null)

  const [{ isDragging }, drag] = useDrag({
    type: "row",
    item: { id, index: id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [, drop] = useDrop({
    accept: "row",
    drop(item: IDragItem, monitor: DropTargetMonitor<IDragItem>) {
      if (ref.current == null) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = id

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      onMoveRow(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  drag(drop(ref))

  return (
    <tr ref={ref} style={{ opacity: isDragging ? 0 : 1 }}>
      {children}
    </tr>
  )
}

export default DraggableRow
