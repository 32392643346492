import { useToast } from "../../components/common/toast/Toast"
import { ToastContext } from "./ToastContext"

export const ToastProvider = ({ children }: { children: any }) => {
  const toastHook = useToast({ autoclose: 5000 })

  return (
    <ToastContext.Provider value={toastHook}>{children}</ToastContext.Provider>
  )
}
