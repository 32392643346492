import { Location } from "react-router-dom"

export const getQueryParamValue = (name?: string) => {
  const params = new URLSearchParams(window.location.search)
  return params.get(name ?? "")
}

export const buildAuthorizationHeader = (token: string) => ({
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
})

export const getAccessTokenFromGoogleRedirect = (location: Location) => {
  const queryParams = new URLSearchParams(location.hash.substring(1))
  return queryParams.get("access_token")
}
