import { useEffect } from "react"

interface useKeyboardProps {
  keyCodes: string[]
  onKeydown: () => void
}

const useKeyboard: React.FC<useKeyboardProps> = ({ keyCodes, onKeydown }) => {
  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (keyCodes.find((k) => k === event.key) != null) onKeydown()
    }
    window.addEventListener("keydown", handleKeydown)

    return () => {
      window.removeEventListener("keydown", handleKeydown)
    }
  }, [onKeydown])
  return <></>
}

export default useKeyboard
