import {
  faEdit,
  faTrash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext, useEffect, useState } from "react"
import { useFieldArray } from "react-hook-form"

import { derechoDirectoOrder } from "../../../constants/base"
import { DerechoDirecto, Regimen } from "../../../domain/entities"
import { ModalContext } from "../../../providers/Modals/ModalContext"
import { useGetRegimenesQuery } from "../../../services/dataApi"
import { convertSnakeCaseToTitleCase } from "../../../utils/transform-utils"
import Button from "../../common/buttons/Button"
import Popover from "../../common/popover/Popover"
import RegimenOption from "../Regimenes/RegimenOption"
import ModalContentDerechoDirecto from "./ModalDerechoDirecto"

interface DerechoDirectoFormProps {
  sexoSelected: string
  control: any
}

const DerechoDirectoForm: React.FC<DerechoDirectoFormProps> = ({
  control,
  sexoSelected,
}) => {
  const [currentItem, setCurrentItem] = useState<DerechoDirecto | null>(null)
  const [currentIndex, setCurrentIndex] = useState<number | null>(null)

  const { fields, insert, remove, update } = useFieldArray({
    control,
    name: "derecho_directo",
  })

  const { openModal, closeModal, isModalOpen } = useContext(ModalContext)

  const handleClose = () => {
    setCurrentIndex(null)
    closeModal()
  }

  const createRow = (item: DerechoDirecto) => {
    insert(fields.length, [item])
    handleClose()
  }

  const updateRow = (item: DerechoDirecto) => {
    update(currentIndex ?? 0, { ...item })
    handleClose()
  }

  const handleAddNew = (e?: React.FormEvent) => {
    e?.preventDefault()
    setCurrentIndex(fields.length)
  }

  const handleEdit = (index: number) => {
    const item = { ...fields[index] }
    setCurrentIndex(index)
    setCurrentItem(item as any)
  }

  const handleRemove = (index: number) => {
    remove(index)
  }

  useEffect(() => {
    if (currentIndex === null) return
    if (currentIndex !== fields.length) {
      openModal(
        `Modificar tiempo de trabajo Nº${currentIndex + 1}`,
        ModalDerechoDirecto,
      )
    } else {
      openModal("Agregar tiempo de trabajo", ModalDerechoDirecto)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex])

  useEffect(() => {
    !isModalOpen && setCurrentItem(null)
    !isModalOpen && setCurrentIndex(null)
  }, [isModalOpen])

  const ModalDerechoDirecto = (
    <ModalContentDerechoDirecto
      item={currentItem}
      sexoSelected={sexoSelected}
      onCreate={createRow}
      onUpdate={updateRow}
    />
  )

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <div className="flex mb-5 justify-between items-center">
        <div className="flex">
          <h6 className="text-2xl font-bold tracking-tight text-gray-400">
            Opcional
          </h6>
          <Button
            color={"primary"}
            size={"sm"}
            className={"ml-10"}
            outline={false}
            onClick={handleAddNew}
          >
            Agregar tiempo de trabajo
          </Button>
        </div>
        <div className="flex">
          <div className="mt-2 ml-5 border-l mb-3 py-2 py-1 rounded-full text-center bg-gray-200">
            <span className="font-semibold text-sm px-4">
              <FontAwesomeIcon icon={faTriangleExclamation} />
              <span className="ml-3 text-xs py-2 px-4">
                Si usted no dispone de fechas de inicio y cese en algún empleo,
                introduzca el tiempo aproximado trabajado en esta sección
              </span>
            </span>
          </div>
        </div>
      </div>

      {fields.length > 0 && (
        <DerechoDirectoTable
          fields={fields}
          handleEdit={handleEdit}
          handleRemove={handleRemove}
          sexoSelected={sexoSelected}
        />
      )}
      {fields.length === 0 && <EmptyTable />}
    </div>
  )
}

const EmptyTable = () => <></>

const DerechoDirectoTable = ({
  fields,
  handleEdit,
  handleRemove,
  sexoSelected,
}: {
  fields: any
  handleEdit: any
  handleRemove: any
  sexoSelected: string
}) => {
  const { data: regimenesFromDB } = useGetRegimenesQuery({})
  return (
    <table className="table-auto divide-y-2 divide-gray-200 bg-white text-sm font-normal text-base">
      <thead className="ltr:text-left rtl:text-right">
        <tr>
          <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-left">
            Nº
          </th>
          {Object.values(derechoDirectoOrder).map((key, index) => (
            <th
              key={index}
              className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-left"
            >
              {convertSnakeCaseToTitleCase(key)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {fields.map((row: any, rowIndex: number) => (
          <tr key={rowIndex}>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700">
              {rowIndex + 1}
            </td>
            {Object.values(derechoDirectoOrder).map(
              (columnKey, columnIndex) => {
                const columnValue = row[columnKey]
                return (
                  <td
                    key={columnIndex}
                    className="whitespace-nowrap px-4 py-2 text-gray-700"
                  >
                    <span>
                      {columnKey === "regimen" ? (
                        <RegimenOption
                          sexo={sexoSelected}
                          option={
                            regimenesFromDB?.find(
                              (r) => r.ID === columnValue,
                            ) as Regimen
                          }
                        />
                      ) : (
                        columnValue
                      )}
                    </span>
                  </td>
                )
              },
            )}
            <td className="whitespace-nowrap px-4 py-2">
              <Popover
                onClick={() => handleEdit(rowIndex)}
                popoverContent={"Modificar tiempo de trabajo"}
                direction="left"
                buttonRender={
                  <span className="mr-3 inline-block rounded bg-green-400 px-4 py-2 text-xs font-medium text-white hover:bg-green-700 cursor:pointer">
                    <FontAwesomeIcon icon={faEdit} size="sm" />
                  </span>
                }
              />
              <Popover
                onClick={() => handleRemove(rowIndex)}
                popoverContent={"Eliminar tiempo de trabajo"}
                direction="right"
                buttonRender={
                  <span className="inline-block rounded bg-red-400 px-4 py-2 text-xs font-medium text-white hover:bg-red-700 cursor:pointer">
                    <FontAwesomeIcon icon={faTrash} size="sm" />
                  </span>
                }
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default DerechoDirectoForm
