import { faQuestion } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns"
import React, { useContext, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"

import {
  UserDeterminationCalculusRequest,
  UserDeterminationImportResponse,
} from "../../../domain/dtos"
import { DerechoHijos, Regimen } from "../../../domain/entities"
import { ModalContext } from "../../../providers/Modals/ModalContext"
import { initializeUseFormObject } from "../../../utils/api-utils"
import { formatST } from "../../../utils/transform-utils"
import Button from "../../common/buttons/Button"
import Input from "../../common/input/Input"
import { ToggleOption } from "../../common/input/Toggle"
import Popover from "../../common/popover/Popover"
import DerechoDirectoForm from "../DerechoDirecto/DerechoDirectoForm"
import HistoricalTable from "../HistoricalTable/HistoricalTable"
import ModalHijos from "../HistoricalTable/ModalHijos"
import ModalHistoriaLaboral from "../HistoricalTable/ModalHistoriaLaboral"
import ModalSicam from "../HistoricalTable/ModalSicam"
import SelectRegimenAportes from "../Regimenes/SelectRegimenAportes"
import { getTipoCalculoVars } from "./DeterminationUtils"

const DeterminationForm = ({
  tipoCalculo,
  previousData,
  importedData,
  regimenesFromDB,
  submitRef,
  onSubmit,
  onHistoriaLaboralImport,
  onSicamImport,
}: {
  tipoCalculo: number
  previousData: UserDeterminationCalculusRequest | undefined
  importedData: UserDeterminationImportResponse | undefined
  regimenesFromDB: Regimen[] | undefined
  submitRef: any
  onSubmit: any
  onHistoriaLaboralImport: any
  onSicamImport: any
}) => {
  const today = format(new Date(), "yyyy-MM-dd")
  const methods = useForm<UserDeterminationCalculusRequest>({
    defaultValues: {
      tipo_calculo: 1,
      apellido: "",
      sexo: "M",
      cuit: "",
      fecha_nacimiento: "",
      fecha_solicitud: "",
      fecha_calculo: "",
      fecha_cierre_computo: "",
      regimen_aportes: 0,
      historia_laboral: [],
      derecho_directo: [],
      derecho_hijos: {},
      df_autonomos: [],
      df_remuneraciones: [],
      fecha_invalidez: "",
      fecha_fallecimiento: "",
      cantidad_hijos: 0,
      conyuges: 0,
    },
  })

  const { openModal, closeModal } = useContext(ModalContext)

  const { isDet, isDeadPension, isInvalidPension } =
    getTipoCalculoVars(tipoCalculo)

  useEffect(() => {
    methods.setValue("tipo_calculo", tipoCalculo)
    methods.setValue("fecha_solicitud", today)
    methods.setValue("fecha_calculo", today)
    methods.setValue("fecha_cierre_computo", today)
  }, [])

  useEffect(() => {
    if (importedData !== undefined) {
      initializeUseFormObject(methods.setValue, importedData)
    } else {
      methods.setValue("historia_laboral", [])
      methods.setValue("df_autonomos", [])
      methods.setValue("df_remuneraciones", [])
    }
  }, [importedData])

  useEffect(() => {
    if (previousData !== undefined) {
      initializeUseFormObject(methods.setValue, previousData)
    }
  }, [previousData])

  const regimenAportesSelected = methods.watch("regimen_aportes", 0)
  const sexoSelected = methods.watch("sexo", "M")
  const hijosTutela = methods.watch("derecho_hijos.hijosTutela", 0)
  const hijosDiscapacidad = methods.watch("derecho_hijos.hijosDiscapacidad", 0)
  const hijosAdopcion = methods.watch("derecho_hijos.hijosAdopcion", 0)
  const hijosAUH = methods.watch("derecho_hijos.hijosAUH", 0)

  const onHistoriaLaboralModalSubmit = (file: File) => {
    closeModal()
    onHistoriaLaboralImport(file)
  }

  const ModalHistoriaLaboralContent = ModalHistoriaLaboral({
    onSubmit: onHistoriaLaboralModalSubmit,
  })

  const onClickHistoriaLaboral = (e: React.FormEvent) => {
    e.preventDefault()
    openModal("Importación de archivo", ModalHistoriaLaboralContent)
  }

  const onSicamModalSubmit = (file: File) => {
    closeModal()
    onSicamImport(file)
  }

  const ModalSicamContent = ModalSicam({
    onSubmit: onSicamModalSubmit,
  })

  const onClickSicam = (e: React.FormEvent) => {
    e.preventDefault()
    openModal("Importación de archivo", ModalSicamContent)
  }

  const onHijosSubmit = (hijos: DerechoHijos) => {
    closeModal()
    methods.setValue("derecho_hijos", hijos)
  }

  const ModalHijosContent = ModalHijos({
    item: { hijosTutela, hijosDiscapacidad, hijosAdopcion, hijosAUH },
    onSubmit: onHijosSubmit,
  })

  const onClickHijos = (e: React.FormEvent) => {
    e.preventDefault()
    openModal(
      "Reconocimiento de Aportes por Tareas de Cuidado",
      ModalHijosContent,
    )
  }

  const updateRegimen = (id: number) => {
    methods.setValue("regimen_aportes", id)
  }

  const regimenAportes = (
    <>
      <label
        className="block mb-2 text-sm font-bold text-gray-900"
        htmlFor={"regimen_aportes"}
      >
        Régimen de finalización
        <Popover
          onClick={() => {}}
          popoverContent={
            "Ingrese actividad en la que continuará trabajando hasta solicitar su jubilación"
          }
          direction="right"
          buttonRender={
            <span className="ml-5 inline-block rounded-full bg-white border-2 border-gray-300 py-[2px] px-[6px] hover:bg-gray-300">
              <FontAwesomeIcon icon={faQuestion} size="xs" color="black" />
            </span>
          }
        />
      </label>
      <SelectRegimenAportes
        options={regimenesFromDB}
        sexoSelected={sexoSelected}
        float={true}
        optionSelected={regimenAportesSelected}
        onSelected={updateRegimen}
      />
    </>
  )

  const listHijo = (n: number, criteria: string) => (
    <span className="">
      Por haber cuidado <strong>{n}</strong> {formatST(n, "hijo/s")}
      {criteria}, te corresponden <strong>{n}</strong>{" "}
      {formatST(n, "año/s adicional/es de aporte/s")}
    </span>
  )

  const hijosResume = (
    <div className="flex flex-col justify-center mt-3">
      {hijosTutela > 0 && listHijo(hijosTutela, "")}
      {hijosDiscapacidad > 0 &&
        listHijo(hijosDiscapacidad, " con discapacidad")}
      {hijosAdopcion > 0 && listHijo(hijosAdopcion, " por adopción")}
      {hijosAUH > 0 &&
        listHijo(
          hijosAUH,
          " y haber sido titular del derecho a AUH por al menos 12 meses",
        )}
      {hijosTutela > 0 && (
        <div className="w-3/4 border-l mb-3 py-2 py-1 rounded-full text-center bg-ct-trademark-light">
          <span className="font-semibold text-sm">
            Total adicionales de aportes por tareas de cuidado:{" "}
            <strong>
              {hijosTutela + hijosDiscapacidad + hijosAdopcion + hijosAUH}
              {formatST(
                hijosTutela + hijosDiscapacidad + hijosAdopcion + hijosAUH,
                " año/s",
              )}
            </strong>{" "}
          </span>
        </div>
      )}
    </div>
  )

  const renderTitle = (
    <>
      Cálculo de{" "}
      {isDet
        ? "determinación"
        : isInvalidPension
        ? "pensión por invalidez"
        : "pensión por fallecimiento"}
    </>
  )

  return (
    <FormProvider {...methods}>
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={methods.handleSubmit(onSubmit)}
        className="w-full"
      >
        <div className="mb-4 text-center">
          <h3 className="text-xl font-bold">{renderTitle}</h3>
        </div>
        <div className="mb-4">
          <h3 className="text-xl font-bold">Datos del solicitante</h3>
        </div>
        <div className="w-full flex flex-col items-center mb-4">
          <div className="w-full flex justify-between">
            <div className="w-1/2 mr-5">
              <div className="flex justify-center mb-3">
                <Button
                  color={"info"}
                  size={"sm"}
                  className={"w-1/3 mr-5"}
                  outline={false}
                  onClick={onClickHistoriaLaboral}
                >
                  Importar Historia laboral
                </Button>
                <Button
                  color={"warning"}
                  size={"sm"}
                  className={"w-1/3 ml-5"}
                  outline={false}
                  onClick={onClickSicam}
                >
                  Importar Sicam
                </Button>
              </div>
              <div className="w-full flex">
                <Input
                  id="cuit"
                  type="text"
                  label="Cuit/Cuil"
                  rules={{
                    required: `Requerido`,
                  }}
                  className="w-1/2 mb-3 mr-3"
                />
                <Input
                  id="apellido"
                  type="text"
                  label="Nombre y apellido"
                  rules={{
                    required: `Requerido`,
                  }}
                  className="w-1/2"
                />
              </div>
              <div className="w-full flex">
                <Input
                  id="fecha_nacimiento"
                  type="date"
                  label="Fecha de nacimiento"
                  rules={{
                    required: `Requerido`,
                  }}
                  className="w-1/2"
                />
                <div className="w-1/2 flex justify-center items-center">
                  <ToggleOption
                    watch={methods.watch}
                    register={methods.register}
                    setValue={methods.setValue}
                    id="sexo"
                    leftOption="Mujer"
                    rightOption="Hombre"
                    leftValue={"M"}
                    rightValue={"H"}
                    checked={previousData?.sexo === "H"}
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 flex flex-col">
              <div className="flex flex-wrap items-center">
                <div className="w-full">
                  {!isDeadPension && (
                    <Input
                      id="fecha_calculo"
                      type="date"
                      label="Fecha de cálculo / solicitud"
                      rules={{
                        required: `Requerido`,
                      }}
                      className="inline-block mr-3"
                      popover={
                        <Popover
                          onClick={() => {}}
                          popoverTitle={"Fecha de inicio de cálculo"}
                          popoverContent={
                            "Puede ser hoy, anterior o porterior, utilizaremos esta fecha para estimar en qué período quiere realizar el cálculo ó solicitud"
                          }
                          direction="bottom"
                          buttonRender={
                            <span className="ml-5 inline-block rounded-full bg-white border-2 border-gray-300 py-[2px] px-[6px] hover:bg-gray-300">
                              <FontAwesomeIcon
                                icon={faQuestion}
                                size="xs"
                                color="black"
                              />
                            </span>
                          }
                        />
                      }
                    />
                  )}
                  {isInvalidPension && (
                    <Input
                      id="fecha_invalidez"
                      type="date"
                      label="Fecha de invalidez"
                      rules={{
                        required: `Requerido`,
                      }}
                      className="inline-block mr-3"
                      popover={
                        <Popover
                          onClick={() => {}}
                          popoverTitle={"Fecha de invalidez"}
                          popoverContent={
                            "Fecha declarada de invalidez de la persona aportante"
                          }
                          direction="bottom"
                          buttonRender={
                            <span className="ml-5 inline-block rounded-full bg-white border-2 border-gray-300 py-[2px] px-[6px] hover:bg-gray-300">
                              <FontAwesomeIcon
                                icon={faQuestion}
                                size="xs"
                                color="black"
                              />
                            </span>
                          }
                        />
                      }
                    />
                  )}
                  {isDeadPension && (
                    <Input
                      id="fecha_fallecimiento"
                      type="date"
                      label="Fecha de fallecimiento"
                      rules={{
                        required: `Requerido`,
                      }}
                      className="inline-block mr-3"
                      popover={
                        <Popover
                          onClick={() => {}}
                          popoverTitle={"Fecha de fallecimiento"}
                          popoverContent={
                            "Fecha de defunción de la persona aportante"
                          }
                          direction="bottom"
                          buttonRender={
                            <span className="ml-5 inline-block rounded-full bg-white border-2 border-gray-300 py-[2px] px-[6px] hover:bg-gray-300">
                              <FontAwesomeIcon
                                icon={faQuestion}
                                size="xs"
                                color="black"
                              />
                            </span>
                          }
                        />
                      }
                    />
                  )}
                  {isDet && (
                    <Input
                      id="fecha_cierre_computo"
                      type="date"
                      label="Fecha de cierre computo"
                      rules={{
                        required: `Requerido`,
                      }}
                      className="inline-block mr-3"
                      popover={
                        <Popover
                          onClick={() => {}}
                          popoverTitle={"Fecha de cierre computo"}
                          popoverContent={
                            "Debe ser posterior a fecha de inicio de cálculo"
                          }
                          direction="bottom"
                          buttonRender={
                            <span className="ml-5 inline-block rounded-full bg-white border-2 border-gray-300 py-[2px] px-[6px] hover:bg-gray-300">
                              <FontAwesomeIcon
                                icon={faQuestion}
                                size="xs"
                                color="black"
                              />
                            </span>
                          }
                        />
                      }
                    />
                  )}
                </div>
                <div className="w-full flex flex-wrap">
                  {isDet && (
                    <>
                      <div className="w-max-[300px]">{regimenAportes}</div>
                      {sexoSelected === "M" && (
                        <div className="w-max-[100px] ml-5">
                          <Button
                            color={"warning"}
                            size={"sm"}
                            className={"w-full max-h-[50px] self-end mt-5"}
                            outline={false}
                            onClick={onClickHijos}
                          >
                            Hijos
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                  {isDeadPension && (
                    <>
                      <Input
                        id="cantidad_hijos"
                        type="number"
                        label="Cantidad de hijos con derecho"
                        rules={{
                          required: `Requerido`,
                          validate: (value: number) =>
                            value < 0 ? `Debe ser mayor o igual a 0` : true,
                        }}
                        className="inline-block mr-3"
                        popover={
                          <Popover
                            onClick={() => {}}
                            popoverTitle={"Cantidad de hijos con derecho"}
                            popoverContent={
                              "Ingrese la cantidad de hijos con discapacidad o menores de 18 años con derecho a la pensión"
                            }
                            direction="bottom"
                            buttonRender={
                              <span className="ml-5 inline-block rounded-full bg-white border-2 border-gray-300 py-[2px] px-[6px] hover:bg-gray-300">
                                <FontAwesomeIcon
                                  icon={faQuestion}
                                  size="xs"
                                  color="black"
                                />
                              </span>
                            }
                          />
                        }
                      />
                      <Input
                        id="conyuges"
                        type="number"
                        label="Cantidad de conyuges"
                        rules={{
                          required: `Requerido`,
                          validate: (value: number) =>
                            value < 0 ? `Debe ser mayor o igual a 0` : true,
                        }}
                        className="inline-block mr-3"
                        popover={
                          <Popover
                            onClick={() => {}}
                            popoverTitle={"Cantidad de conyuges"}
                            popoverContent={
                              "Ingrese la cantidad de conyuges ó convivientes con derecho a la pensión"
                            }
                            direction="bottom"
                            buttonRender={
                              <span className="ml-5 inline-block rounded-full bg-white border-2 border-gray-300 py-[2px] px-[6px] hover:bg-gray-300">
                                <FontAwesomeIcon
                                  icon={faQuestion}
                                  size="xs"
                                  color="black"
                                />
                              </span>
                            }
                          />
                        }
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="w-full mr-5">
                {sexoSelected === "M" && isDet && hijosResume}
              </div>
            </div>
          </div>
        </div>
        <HistoricalTable
          control={methods.control}
          register={methods.register}
          setValue={methods.setValue}
          watch={methods.watch}
          sexoSelected={sexoSelected}
        />
        <DerechoDirectoForm
          control={methods.control}
          sexoSelected={sexoSelected}
        />
        <button type="submit" ref={submitRef} style={{ display: "none" }} />
      </form>
    </FormProvider>
  )
}

export default DeterminationForm
