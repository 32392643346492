import { useContext } from "react"

import { SingleFileImportRequest } from "../domain/dtos"
import { ToastContext } from "../providers/Toasts/ToastContext"
import { useAppDispatch } from "../redux/config/hooks"
import determinationApi, {
  useUploadHistoriaLaboralMutation,
} from "./determinationApi"

export const useDeterminationService = () => {
  const { showToast } = useContext(ToastContext)
  const [onSubmit, { isLoading, data }] = useUploadHistoriaLaboralMutation()
  const dispatchLocalSubmit = useAppDispatch()

  const uploadDetermination = async (request: SingleFileImportRequest) => {
    try {
      await onSubmit(request).unwrap()
      showToast({
        title: "Éxito!",
        message: "Tu historia laboral se importó correctamente",
        type: "success",
      })
    } catch (error) {
      showToast({
        title: "Error en la importación!",
        message: "Verifica el archivo ingresado y vuelve a intentar",
        type: "error",
      })
    }
  }

  const resetState = () =>
    dispatchLocalSubmit(determinationApi.util.resetApiState())

  return {
    uploadDetermination,
    resetState,
    isLoading,
    data,
  }
}
