import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import Alert from "../components/common/alerts/Alert"
import Button from "../components/common/buttons/Button"
import Input from "../components/common/input/Input"
import { Spinner } from "../components/common/spinner/Spinner"
import { ToastContext } from "../providers/Toasts/ToastContext"
import Paths from "../routes/paths"
import { useGenerateRecoverMutation } from "../services/sessionsApi"
import { getQueryParamValue } from "../utils/query"

const RecoverPage = () => {
  const navigate = useNavigate()
  const { showToast } = useContext(ToastContext)
  const [recoverRequest, { isLoading }] = useGenerateRecoverMutation()
  const alreadySent = getQueryParamValue("alreadysent") === "true" ?? false
  const recoverLinkInvalid =
    getQueryParamValue("recoverLinkInvalid") === "true" ?? false

  const methods = useForm<any>({
    defaultValues: {
      email: "",
    },
  })

  useEffect(() => {
    if (recoverLinkInvalid) {
      showToast({
        title: "Link de recuperación inválido",
        message:
          "Debes re-enviar el email de recuperación. Tienes 15 minutos para restablecer tu contraseña",
        type: "warning",
      })
    }
  }, [])

  const onSubmit = async (email: string) => {
    try {
      await recoverRequest(email).unwrap()
      setTimeout(() => {
        navigate(Paths.forgot + "?alreadysent=true")
      }, 10)
    } catch (error: any) {
      if (error?.status === 403) {
        showToast({
          title: "Debes esperar 1 minuto",
          message:
            "Debes esperar un minuto antes de reenviar el email de recuperación",
          type: "warning",
        })
      } else {
        showToast({
          title: "Error al enviar email de recuperación",
          message: "Verifica los datos ingresados y vuelve a intentar",
          type: "error",
        })
      }
    }
  }

  return (
    <>
      {isLoading && <Spinner align="center"></Spinner>}
      {!isLoading && (
        <section className="w-full max-w-lg mx-auto my-auto py-16">
          <div className="mx-auto flex justify-center items-center mt-10">
            <div className="">
              <a
                href={Paths.login}
                className="inline-flex items-center space-x-0.5 mb-2 text-sm opacity-60 hover:opacity-80"
              >
                <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
                <span className="ml-3">Volver</span>
              </a>
              <h3 className="font-bold tracking-tight text-gray-900">
                Restablecer contraseña
              </h3>
              <h6 className="tracking-tight text-gray-900 mt-3">
                Te enviaremos un email a tu cuenta de email registrada con las
                instrucciones para reestablecer tu contraseña.
              </h6>
              <FormProvider {...methods}>
                <form
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onSubmit={methods.handleSubmit(onSubmit)}
                  className="w-full"
                >
                  <div className="w-full flex flex-wrap mt-10">
                    <Input
                      id="email"
                      type="text"
                      label="Email"
                      rules={{
                        pattern: {
                          value: /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: `Debe tener formato email`,
                        },
                        required: `Requerido`,
                      }}
                      className="w-full"
                    />
                  </div>
                  <div className="w-full flex items-center justify-center flex-wrap mt-3">
                    {alreadySent && (
                      <div className="mb-7">
                        {" "}
                        <Alert type="info">
                          Te enviamos un email de recuperación a tu casilla de
                          correo, por favor asegúrate de verificar la bandeja de
                          entrada y{" "}
                          <strong>la carpeta de spam o no deseados </strong>
                        </Alert>
                      </div>
                    )}
                    <Button
                      color={"primary"}
                      size={"sm"}
                      outline={false}
                      className="w-full"
                    >
                      Enviar email de recuperación
                    </Button>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default RecoverPage
