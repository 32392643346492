import React, { createContext } from "react"

interface ModalContextProps {
  isModalOpen: boolean
  openModal: (header: string, content: React.ReactNode) => void
  closeModal: () => void
}

export const ModalContext = createContext<ModalContextProps>({
  isModalOpen: false,
  openModal: () => {},
  closeModal: () => {},
})
