import React, { useContext, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { Spinner } from "../components/common/spinner/Spinner"
import { ToastContext } from "../providers/Toasts/ToastContext"
import Paths from "../routes/paths"
import { usePostOauthUserMutation } from "../services/sessionsApi"
import { setCookie } from "../utils/cookies"
import { getAccessTokenFromGoogleRedirect } from "../utils/query"

const RedirectFromGoogle = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { showToast } = useContext(ToastContext)
  const [onSubmit] = usePostOauthUserMutation()
  const accessToken = getAccessTokenFromGoogleRedirect(location)

  useEffect(() => {
    if (accessToken !== null) {
      showToast({
        title: "Éxito",
        message: "Tu registración con google fue exitosa",
        type: "success",
      })
      void onSubmit(accessToken).unwrap()
      setCookie("access_token", accessToken, 30)
      setTimeout(() => {
        navigate(Paths.account)
      }, 100)
    }
  }, [accessToken])

  return (
    <>
      <Spinner align="center"></Spinner>
    </>
  )
}

export default RedirectFromGoogle
