import React, { useEffect, useState } from "react"
import { MagicMotion } from "react-magic-motion"
import { useLocation } from "react-router-dom"

import useBreakpoint, { Breakpoints } from "../../hooks/useBreakpoint"
import useScroll from "../../hooks/useScroll"
import Paths from "../../routes/paths"
import logo from "./../../assets/images/logo.png"
import SidebarLanding from "./SidebarLanding"

const HeaderLanding: React.FC = () => {
  const location = useLocation()
  const [showFloatingSidebar, setShowFloatingSidebar] = useState(false)

  const breakpoint = useBreakpoint()
  useEffect(() => {
    if (breakpoint >= Breakpoints.lg) setShowFloatingSidebar(false)
  }, [breakpoint])

  const isScrolled = useScroll()
  const isRootPath = Paths.home === location.pathname
  const renderHeader =
    (isRootPath && !isScrolled) || (!isRootPath && isScrolled)
  const enableSidebar = showFloatingSidebar

  return (
    <MagicMotion>
      <header
        className={`w-full z-10 ${
          isRootPath && isScrolled
            ? "fixed bg-ct-trademark-secondary opacity-95"
            : "absolute"
        }${!isRootPath ? "fixed bg-ct-trademark-secondary opacity-95" : ""}`}
        key={"exclude"}
      >
        {enableSidebar && <SidebarLanding />}
        <div className="px-4 mx-auto sm:px-6 lg:px-8">
          <div
            className={`flex items-center ${
              renderHeader ? "justify-end" : "justify-between"
            } h-16 lg:h-20`}
          >
            {!renderHeader && (
              <div className="flex-shrink-0">
                <a href={Paths.home} title="" className="flex">
                  <img className="w-auto h-8" src={logo} alt="" />
                </a>
              </div>
            )}

            <button
              type="button"
              className="inline-flex p-2 text-black transition-all duration-200 rounded-md lg:hidden focus:bg-gray-100 hover:bg-gray-100"
              onClick={() => setShowFloatingSidebar(!showFloatingSidebar)}
            >
              <svg
                className="block w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>

              <svg
                className="hidden w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>

            <div className="hidden ml-auto lg:flex lg:items-center lg:justify-center lg:space-x-10">
              <a
                href={isRootPath ? "#main" : Paths.home}
                title=""
                className="text-base font-semibold text-black transition-all duration-200 px-4 py-2 rounded-lg hover:bg-gray-200"
              >
                {" "}
                Soluciones{" "}
              </a>

              <a
                href={isRootPath ? "#nosotros" : Paths.home}
                title=""
                className="text-base font-semibold text-black transition-all duration-200 px-4 py-2 rounded-lg hover:bg-gray-200"
              >
                {" "}
                Nosotros{" "}
              </a>

              <a
                href={isRootPath ? "#pricing" : Paths.home}
                title=""
                className="text-base font-semibold text-black transition-all duration-200 px-4 py-2 rounded-lg hover:bg-gray-200"
              >
                {" "}
                Planes{" "}
              </a>

              <a
                href={Paths.login}
                className="block rounded-lg bg-transparent px-4 py-2 text-sm font-bold text-gray-800 text-center hover:bg-gray-200"
              >
                {" "}
                Iniciar sesión{" "}
              </a>
              <a
                href={Paths.register}
                className="block rounded-lg bg-blue-100 px-4 py-2 text-sm font-bold text-black text-center hover:bg-gray-500 hover:text-white"
                role="button"
              >
                {" "}
                Prueba gratuita{" "}
              </a>
            </div>
          </div>
        </div>
      </header>
    </MagicMotion>
  )
}

export default HeaderLanding
