import React, { ReactNode } from "react"

import { ToastProps } from "../../components/common/toast/Toast"

interface ToastContextProps {
  toasts: ReactNode[]
  showToast: (props: ToastProps) => void
}

export const ToastContext = React.createContext<ToastContextProps>({
  toasts: [],
  showToast: () => {},
})
