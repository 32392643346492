import React from "react"

import { ColorType, TextSize } from "../../../constants/styles"

interface ButtonProps {
  color: string
  size: string
  outline: boolean
  className?: string
  onClick?: (e: React.FormEvent) => void
  children: React.ReactNode
}

const Button: React.FC<ButtonProps> = ({
  color,
  size,
  outline,
  className: customClass = "",
  onClick,
  children,
}) => {
  color = Object.keys(ColorType).includes(color)
    ? ColorType[color as keyof typeof ColorType]
    : ColorType.default

  size = Object.keys(TextSize).includes(size)
    ? TextSize[size as keyof typeof TextSize]
    : TextSize.default

  const baseClass =
    "font-medium rounded-lg px-5 py-2.5 text-center inline-flex items-center focus:ring-4 focus:outline-none border"
  let typeClass = `hover:text-white border-${color}-500 hover:bg-${color}-800 focus:ring-${color}-900 
  dark:hover:text-white dark:border-${color}-500 dark:hover:bg-${color}-800 dark:focus:ring-${color}-900`

  typeClass += outline
    ? ` text-${color}-500 dark:text-${color}-500`
    : ` text-white dark:text-white bg-${color}-500 dark:bg-${color}-500`

  const className = `${customClass} ${baseClass} ${typeClass} ${size}`
  const type = onClick !== undefined ? "button" : "submit"

  return (
    <button type={type} className={className} onClick={onClick}>
      <span className="sr-only">{children}</span>
      <span className="text-sm font-medium text-center w-full">{children}</span>
    </button>
  )
}

export default Button
