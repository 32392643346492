import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { LoginRequest, RecoverUserRequest, UserRequest } from "../domain/dtos"

const baseUrl =
  (import.meta.env.VITE_BACKEND_URL as string) + "/api/v1/sessions"

const sessionsApi = createApi({
  reducerPath: "sessions",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getUser: builder.query<any, string>({
      query: (accessToken) => `/users?access_token=${accessToken}`,
    }),
    postOauthUser: builder.mutation<any, string>({
      query: (accessToken) => ({
        url: `/oauth/google`,
        method: "POST",
        body: { access_token: accessToken },
      }),
    }),
    postUser: builder.mutation<string, UserRequest>({
      query: (request) => ({
        url: `/users`,
        method: "POST",
        body: request,
      }),
      transformResponse: (response: any) => response.access_token,
    }),
    modifyUser: builder.mutation<string, RecoverUserRequest>({
      query: (request) => ({
        url: `/users`,
        method: "PUT",
        body: request,
      }),
    }),
    login: builder.mutation<string, LoginRequest>({
      query: (request) => ({
        url: `/login`,
        method: "POST",
        body: request,
      }),
      transformResponse: (response: any) => response.access_token,
    }),
    generateRecover: builder.mutation<any, string>({
      query: (email) => ({
        url: `/recover`,
        method: "POST",
        body: email,
      }),
    }),
    validateRecover: builder.query<any, string>({
      query: (ssid) => `/recover?ssid=${ssid}`,
    }),
  }),
})

export const {
  useGetUserQuery,
  usePostOauthUserMutation,
  usePostUserMutation,
  useLoginMutation,
  useGenerateRecoverMutation,
  useValidateRecoverQuery,
  useModifyUserMutation,
} = sessionsApi
export default sessionsApi
