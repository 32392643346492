import PropTypes from "prop-types"
import React, { ReactElement } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { isNullOrEmpty } from "../../../utils/validations"

interface InputProps {
  id: string
  type: string
  placeholder?: string
  hidden?: boolean
  label?: string
  className?: string
  popover?: JSX.Element
  rules?: object
  disabled?: boolean
  value?: string
  initialValue?: any
  children?: ReactElement
}

const Input: React.FC<InputProps> = ({
  id,
  type,
  placeholder,
  hidden,
  label,
  className,
  popover,
  rules,
  disabled,
  value,
  initialValue,
  children,
}) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext()
  const errorMsg = errors?.[id]?.message
  const inputWatch = watch(id)
  return (
    <div className={`${className as string} mb-4`}>
      {isNullOrEmpty(hidden) && (
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor={id}
        >
          {type !== "checkbox" && label}
          {popover}
        </label>
      )}
      <Controller
        control={control}
        name={id}
        defaultValue={initialValue}
        rules={rules}
        render={({ field }) => (
          <>
            <input
              {...field}
              className={`${
                type !== "checkbox"
                  ? "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  : "checkbox"
              } ${errors?.[id] != null ? "border-red-500" : ""}`}
              id={id}
              type={type}
              required={(rules as any)?.required}
              hidden={hidden}
              placeholder={placeholder}
              value={value ?? inputWatch}
              disabled={disabled}
            />
            {children}
          </>
        )}
      />
      {errorMsg != null && (
        <p className="text-red-500 text-xs italic">{errorMsg as string}</p>
      )}
    </div>
  )
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "text",
    "number",
    "date",
    "file",
    "password",
    "checkbox",
  ]).isRequired,
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
  hidden: PropTypes.bool,
  label: PropTypes.string,
  rules: PropTypes.object,
}

export default Input
