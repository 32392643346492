import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import React, { ReactNode, useState } from "react"

export interface ToastProps {
  message: string
  title: string
  type: string
  position?: "right" | "center" | "left"
}

export const Toast: React.FC<ToastProps> = ({
  message,
  title,
  type = "success",
  position = "center",
}) => {
  const [showToast, setShowToast] = useState(true)

  if (!showToast) return null

  const typeToColor = {
    success: "#48BB78", // Green
    error: "#E53E3E", // Red
    warning: "#ED8936", // Yellow
    info: "#4299E1", // Blue
  }

  const color = typeToColor[type as keyof typeof typeToColor]

  return (
    <div
      role="alert"
      className={`fixed top-0 ${
        position === "center"
          ? "left-1/2 transform -translate-x-1/2"
          : position === "right"
          ? "right-0"
          : "left-0"
      } z-50 m-6 rounded-xl border bg-white p-4 shadow-xl`}
      style={{ borderColor: color }}
    >
      <div className="flex items-start gap-4">
        <span className="text-green-600">{/* SVG */}</span>

        <div className="flex-1">
          <strong className="block font-medium text-gray-900">{title}</strong>
          <p className="mt-1 text-sm text-gray-700">{message}</p>
        </div>

        <button
          onClick={() => setShowToast(false)}
          className={`transition hover:text-gray-600`}
        >
          <span className="sr-only">Dismiss popup</span>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </div>
  )
}

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["success", "error", "warning", "info"]).isRequired,
}

export const useToast = ({ autoclose = 3000 }: { autoclose?: number }) => {
  const [toasts, setToasts] = useState<ReactNode[]>([])

  const showToast = (props: ToastProps) => {
    const clone = [...toasts]
    clone.push(
      <Toast
        key={Date.now()}
        message={props.message}
        title={props.title}
        type={props.type}
      />,
    )
    setToasts(clone)
    setTimeout(() => {
      setToasts((prevToasts) => prevToasts.slice(1))
    }, autoclose)
  }

  return { toasts, showToast }
}
