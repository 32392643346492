import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import {
  HistoriaLaboralImportResponse,
  SingleFileImportRequest,
  UserDeterminationCalculusRequest,
  UserDeterminationImportRequest,
  UserDeterminationImportResponse,
} from "../domain/dtos"
// import { numbersToQueryParams } from "../utils/api-utils"

const baseUrl = (import.meta.env.VITE_BACKEND_URL as string) + "/api/v1"

const determinationApi = createApi({
  reducerPath: "determination",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    uploadDetermination: builder.mutation<
      UserDeterminationImportResponse,
      UserDeterminationImportRequest
    >({
      query: ({
        sexo,
        fecha_nacimiento: fechaNacimiento,
        fecha_solicitud: fechaSolicitud,
        fecha_cierre_computo: fechaCierreComputo,
        historia_laboral: historiaLaboral,
        sicam,
        // deuda_aportes: deudaAportes,
      }) => {
        const formData = new FormData()
        formData.append("sexo", sexo as string)
        formData.append("fecha_nacimiento", fechaNacimiento as string)
        formData.append("fecha_solicitud", fechaSolicitud as string)
        formData.append("fecha_cierre_computo", fechaCierreComputo as string)
        if (historiaLaboral !== undefined && historiaLaboral.length > 0) {
          formData.append("historia_laboral", historiaLaboral[0])
        }

        if (sicam !== undefined && sicam.length > 0) {
          formData.append("sicam", sicam[0])
        }

        // const deuda = numbersToQueryParams(deudaAportes)

        return {
          url: `/determination/upload`,
          method: "POST",
          body: formData,
        }
      },
    }),
    uploadHistoriaLaboral: builder.mutation<
      HistoriaLaboralImportResponse,
      SingleFileImportRequest
    >({
      query: ({
        historia_laboral: historiaLaboral,
        sicam,
        historia_laboral_previous: historiaLaboralPrevious,
        sicam_previous: sicamPrevious,
        cuit,
        apellido,
      }) => {
        const formData = new FormData()
        if (historiaLaboral !== undefined && historiaLaboral instanceof File) {
          formData.append("historia_laboral", historiaLaboral)
        } else if (Array.isArray(historiaLaboralPrevious)) {
          formData.append(
            "historia_laboral",
            JSON.stringify(historiaLaboralPrevious),
          )
        }

        if (sicam !== undefined && sicam instanceof File) {
          formData.append("sicam", sicam)
        } else if (Array.isArray(sicamPrevious)) {
          formData.append("sicam", JSON.stringify(sicamPrevious))
        }

        formData.append("cuit", cuit as string)
        formData.append("apellido", apellido as string)

        return {
          url: `/determination/upload`,
          method: "POST",
          body: formData,
        }
      },
    }),
    calculateDetermination: builder.mutation<
      string,
      UserDeterminationCalculusRequest
    >({
      query: (request) => {
        return {
          url: "/determination/calculate",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request),
          responseType: "arraybuffer", // Handle the response as binary data
        }
      },
      transformResponse: (response: any) => {
        // Convert the Buffer to Uint8Array
        const uint8array = new Uint8Array(response?.data)

        // Convert the ArrayBuffer to Blob
        const blob = new Blob([uint8array], { type: "application/pdf" })

        // Convert the Blob to a base64-encoded string
        const base64String = URL.createObjectURL(blob)

        return base64String
      },
    }),
  }),
})

export const {
  useUploadDeterminationMutation,
  useUploadHistoriaLaboralMutation,
  useCalculateDeterminationMutation,
} = determinationApi
export default determinationApi
