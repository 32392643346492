import React, { useContext, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import GoogleLogo from "../assets/images/google.svg"
import Button from "../components/common/buttons/Button"
import Input from "../components/common/input/Input"
import PasswordInput from "../components/common/input/PasswordInput"
import { Spinner } from "../components/common/spinner/Spinner"
import { UserRequest } from "../domain/dtos"
import { ToastContext } from "../providers/Toasts/ToastContext"
import Paths from "../routes/paths"
import { usePostUserMutation } from "../services/sessionsApi"
import { getCookie, setCookie } from "../utils/cookies"
import { getGoogleUrl } from "../utils/getGoogleUrl.js"
import { isNullOrEmpty } from "../utils/validations"

const RegisterPage = () => {
  const navigate = useNavigate()
  const { showToast } = useContext(ToastContext)
  const [postUser, { isLoading, data: accessToken }] = usePostUserMutation()

  const methods = useForm<UserRequest>({
    defaultValues: {
      name: "",
      email: "",
      password: "",
    },
  })

  const password = methods.watch("password", "")

  const onSubmit = async (request: UserRequest) => {
    try {
      await postUser(request).unwrap()
      showToast({
        title: "Registro exitoso!",
        message: "Comenzá a calculando tu determinación de derecho",
        type: "success",
      })
    } catch (error) {
      showToast({
        title: "Error en el  resgistro",
        message: "Verifica los datos ingresados y vuelve a intentar",
        type: "error",
      })
    }
  }

  useEffect(() => {
    if (isNullOrEmpty(accessToken)) return
    setCookie("access_token", accessToken as string, 30)
    while (getCookie("access_token") === undefined) {
      /* empty */
    }
    navigate(Paths.account)
  }, [accessToken])

  const redirectTo = Paths.login_successful

  return (
    <>
      {isLoading && <Spinner align="center"></Spinner>}
      {!isLoading && (
        <section className="w-full max-w-lg mx-auto my-auto py-16">
          <div className="mx-auto flex justify-center items-center mt-10">
            <div className="">
              <h3 className="font-bold tracking-tight text-gray-900">
                Empezá con tu cuenta gratuita
              </h3>
              <h6 className="tracking-tight text-gray-900">
                Registrate en segundos
              </h6>
              <FormProvider {...methods}>
                <form
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onSubmit={methods.handleSubmit(onSubmit)}
                  className="w-full"
                >
                  <div className="w-full flex flex-wrap mt-10">
                    <Input
                      id="name"
                      type="text"
                      label="Nombre"
                      rules={{
                        required: `Requerido`,
                      }}
                      className="w-full"
                    />
                    <Input
                      id="email"
                      type="text"
                      label="Email"
                      rules={{
                        pattern: {
                          value: /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: `Debe tener formato email`,
                        },
                        required: `Requerido`,
                      }}
                      className="w-full"
                    />
                    <PasswordInput
                      id="password"
                      label="Contraseña"
                      className="w-full"
                      password={password}
                    />
                  </div>
                  <div className="flex items-center gap-3 mb-8">
                    <Input
                      id="agree"
                      type="checkbox"
                      label="agree"
                      rules={{
                        pattern: {
                          value: /^1/,
                          message: `Debes aceptar los términos y condiciones`,
                        },
                        required: `Requerido`,
                      }}
                      initialValue={"0"}
                      className="w-full"
                    >
                      <label className="ml-5 text-tiny" htmlFor="agree">
                        Acepto los{" "}
                        <a
                          href={Paths.terms}
                          target="_blank"
                          className="underline hover:text-secondary-600"
                          rel="noreferrer"
                        >
                          Términos y condiciones
                        </a>{" "}
                        y las{" "}
                        <a
                          href={Paths.policy}
                          target="_blank"
                          className="underline hover:text-secondary-600"
                          rel="noreferrer"
                        >
                          Políticas de privacidad
                        </a>
                      </label>
                    </Input>
                  </div>
                  <div className="w-full flex items-center justify-center flex-wrap mt-3">
                    <Button
                      color={"primary"}
                      size={"sm"}
                      outline={false}
                      className="w-full"
                    >
                      Registrarse
                    </Button>
                  </div>
                </form>
              </FormProvider>
              <div className="my-4 py-4 relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-200"></div>
                </div>
                <div className="relative flex justify-center leading-6">
                  <span className="bg-white px-4 text-black text-opacity-75">
                    o registrarse con
                  </span>
                </div>
              </div>
              <a
                className="px-7 py-2 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full flex justify-center items-center mb-3"
                style={{ backgroundColor: "#3b5998" }}
                href={getGoogleUrl(redirectTo)}
                role="button"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
              >
                <img
                  className="pr-2"
                  src={GoogleLogo}
                  alt=""
                  style={{ height: "2rem" }}
                />
                Google
              </a>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default RegisterPage
