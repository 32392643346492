import React, { useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"

import { Spinner } from "../../components/common/spinner/Spinner"
import useBreakpoint, { Breakpoints } from "../../hooks/useBreakpoint"
import Paths from "../../routes/paths"
import { useGetUserQuery } from "../../services/sessionsApi"
import { getCookie } from "../../utils/cookies"
import { isNullOrEmpty } from "../../utils/validations"
import Footer from "../Footer"
import HeaderServices from "./HeaderServices"
import SidebarServices from "./SidebarServices"

const LayoutLanding = () => {
  const navigate = useNavigate()
  const [showFloatingSidebar, setShowFloatSidebar] = useState(false)
  const [isNarrowScreen, setIsNarrowScreen] = useState(false)

  const breakpoint = useBreakpoint()
  useEffect(() => {
    if (breakpoint < Breakpoints.xl) {
      setIsNarrowScreen(true)
    } else {
      setIsNarrowScreen(false)
    }
  }, [breakpoint])

  const onHamburgerClick = () => setShowFloatSidebar(!showFloatingSidebar)

  const gridCols = isNarrowScreen ? "grid-cols-[1fr]" : "grid-cols-[230px_1fr]"
  const gridRows = "grid-rows-[70px_auto_70px]"
  const renderSidebar = !isNarrowScreen || showFloatingSidebar

  const cookie = getCookie("access_token") as string
  const { error, data, isFetching } = useGetUserQuery(cookie)
  if (!isNullOrEmpty(error) && isNullOrEmpty(data)) {
    setTimeout(() => {
      navigate(Paths.home)
    }, 10)
  }

  return (
    <div className="">
      <div
        className={`w-full min-h-screen h-full grid ${gridRows} ${gridCols}`}
      >
        <div
          className={`${isNarrowScreen ? "col-start-1" : "col-start-2"} ${
            showFloatingSidebar ? "ml-[230px]" : " "
          } col-span-2 row-start-1 row-end-2 h-[70px]`}
        >
          <HeaderServices
            onHamburgerClick={onHamburgerClick}
            showHamburger={isNarrowScreen}
          />
        </div>
        {renderSidebar && (
          <div
            className={`${
              isNarrowScreen && showFloatingSidebar
                ? "w-[230px] fixed h-screen z-10"
                : "col-start-1 col-span-1 row-start-1 row-end-3"
            }`}
          >
            <SidebarServices />
          </div>
        )}
        <div
          className={`${
            isNarrowScreen ? "col-start-1 col-span-2" : "col-start-2 col-span-2"
          } row-start-2 row-end-3 bg-yellow-50 h-full w-full overflow-hidden`}
        >
          {isFetching && <Spinner align="center"></Spinner>}
          {!isFetching && <Outlet />}
        </div>
        <div className="col-start-1 col-span-2 row-start-3 row-end-4">
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default LayoutLanding
