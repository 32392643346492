import { faInfo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext, useEffect } from "react"
import { useForm } from "react-hook-form"

import { DerechoHijos } from "../../../domain/entities"
import { ToastContext } from "../../../providers/Toasts/ToastContext"
import { isNullOrEmpty } from "../../../utils/validations"
import Button from "../../common/buttons/Button"
import Popover from "../../common/popover/Popover"

interface ModalProps {
  onSubmit: (data: DerechoHijos) => void
  item: DerechoHijos
}

const ModalHijos: React.FC<ModalProps> = ({ onSubmit, item }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<DerechoHijos>()

  const { showToast } = useContext(ToastContext)

  const hijosTutela = watch("hijosTutela")

  useEffect(() => {
    if (item !== undefined) {
      setValue("hijosTutela", item.hijosTutela)
      setValue("hijosDiscapacidad", item.hijosDiscapacidad)
      setValue("hijosAdopcion", item.hijosAdopcion)
      setValue("hijosAUH", item.hijosAUH)
    }
  }, [])

  useEffect(() => {
    if (!isNullOrEmpty(errors)) {
      const validation = Object.values(errors).at(0)?.message ?? ""
      showToast({
        title: "Error en la carga de hijos",
        message: validation,
        type: "error",
      })
    }
  }, [errors.hijosAUH, errors.hijosAdopcion, errors.hijosDiscapacidad])

  const onSubmitForm = (hijos: DerechoHijos) => {
    hijos.hijosTutela = parseInt(hijos.hijosTutela as any)
    hijos.hijosAdopcion = parseInt(hijos.hijosAdopcion as any)
    hijos.hijosDiscapacidad = parseInt(hijos.hijosDiscapacidad as any)
    hijos.hijosAUH = parseInt(hijos.hijosAUH as any)
    onSubmit(hijos)
  }

  const onClear = (e: React.FormEvent) => {
    e.preventDefault()
    setValue("hijosTutela", 0)
    setValue("hijosDiscapacidad", 0)
    setValue("hijosAdopcion", 0)
    setValue("hijosAUH", 0)
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className="grid gap-4 mb-4 sm:grid-cols-2">
        <div className="flex flex-col justify-end min-h-[110px]">
          <div className="flex h-[50px] items-end justify-between mb-2 min-h-[34px]">
            <h6 className="block text-sm font-medium text-gray-900 ">
              Ingrese cantidad total de hijos
            </h6>
            <Popover
              popoverTitle={"Hijos"}
              popoverContent={"Total de hijos nacidos con vida"}
              buttonRender={
                <span className="ml-5 inline-block rounded bg-blue-400 px-4 py-2 text-xs font-medium text-white hover:bg-blue-700">
                  <FontAwesomeIcon icon={faInfo} />
                </span>
              }
              className="max-w-[250px]"
            />
          </div>
          <input
            {...register("hijosTutela", { required: true })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="number"
            placeholder="Hijos"
          />
        </div>
        <div className="flex flex-col justify-end min-h-[110px]">
          <div className="flex h-[50px] items-end justify-between mb-2 min-h-[34px]">
            <h6 className="block text-sm font-medium text-gray-900 ">
              Ingrese cantidad de hijos <strong>con discapacidad</strong>
            </h6>
            <Popover
              popoverTitle={"Hijos con discapacidad"}
              popoverContent={
                "Cantidad de hijos con discapacidad, menores de 18 años"
              }
              buttonRender={
                <span className="ml-5 inline-block rounded bg-blue-400 px-4 py-2 text-xs font-medium text-white hover:bg-blue-700">
                  <FontAwesomeIcon icon={faInfo} />
                </span>
              }
              className="max-w-[250px]"
            />
          </div>
          <input
            {...register("hijosDiscapacidad", {
              max: {
                value: hijosTutela,
                message:
                  "El número de hijos con discapacidad debe ser igual o menor a la cantidad de hijos",
              },
            })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="number"
            placeholder="Hijos por discapacidad"
          />
        </div>
        <div className="flex flex-col justify-end min-h-[110px]">
          <div className="flex items-end justify-between mb-2 min-h-[34px]">
            <h6 className="block text-sm font-medium text-gray-900 ">
              Ingrese cantidad de hijos <strong>por adopción</strong>
            </h6>
            <Popover
              popoverTitle={"Hijos por adopción"}
              popoverContent={
                "Cantidad de hijos que se adoptaron siendo menores"
              }
              buttonRender={
                <span className="ml-5 inline-block rounded bg-blue-400 px-4 py-2 text-xs font-medium text-white hover:bg-blue-700">
                  <FontAwesomeIcon icon={faInfo} />
                </span>
              }
              className="max-w-[250px]"
            />
          </div>
          <input
            {...register("hijosAdopcion", {
              max: {
                value: hijosTutela,
                message:
                  "El número de hijos por adopción debe ser igual o menor a la cantidad de hijos",
              },
            })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="number"
            placeholder="Hijos por adopción"
          />
        </div>
        <div className="flex flex-col justify-end min-h-[110px]">
          <div className="flex items-end justify-between mb-2 min-h-[34px]">
            <h6 className="block text-sm font-medium text-gray-900 ">
              Ingrese la cantidad de hijos{" "}
              <strong>por los que cobró AUH</strong> al menos un año
            </h6>
            <Popover
              popoverTitle={"Hijos por AUH"}
              popoverContent={
                "Cantidad de hijos por los que cobró AUH al menos 12 meses continuos o discontinuos"
              }
              buttonRender={
                <span className="ml-5 inline-block rounded bg-blue-400 px-4 py-2 text-xs font-medium text-white hover:bg-blue-700">
                  <FontAwesomeIcon icon={faInfo} />
                </span>
              }
              className="max-w-[250px]"
            />
          </div>
          <input
            {...register("hijosAUH", {
              max: {
                value: hijosTutela,
                message:
                  "El número de hijos por AUH debe ser igual o menor a la cantidad de hijos",
              },
            })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="number"
            placeholder="Hijos por AUH"
          />
        </div>
      </div>
      <div className="flex items-center justify-between space-x-4">
        <Button color={"danger"} size={"sm"} outline={false} onClick={onClear}>
          Limpiar
        </Button>
        <Button color={"primary"} size={"sm"} outline={false}>
          {item !== undefined && item.hijosTutela > 0
            ? "Actualizar"
            : "Ingresar"}
        </Button>
      </div>
    </form>
  )
}

export default ModalHijos
