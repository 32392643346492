import React, { useContext, useEffect, useState } from "react"

import { DerechoDirecto } from "../../../domain/entities"
import { ToastContext } from "../../../providers/Toasts/ToastContext"
import { useGetRegimenesQuery } from "../../../services/dataApi"
import Button from "../../common/buttons/Button"
import SelectRegimenAportes from "../Regimenes/SelectRegimenAportes"

interface ModalProps {
  item: DerechoDirecto | null
  sexoSelected: string
  onCreate: (item: DerechoDirecto) => void
  onUpdate: (item: DerechoDirecto) => void
}

const ModalContentDerechoDirecto: React.FC<ModalProps> = ({
  item,
  sexoSelected,
  onCreate,
  onUpdate,
}) => {
  const { data: regimenesFromDB } = useGetRegimenesQuery({})

  const { showToast } = useContext(ToastContext)

  const [regimen, setRegimen] = useState(0)
  const [Años, setAños] = useState(0)
  const [Meses, setMeses] = useState(0)
  const [dias, setDias] = useState(0)

  const isUpdate = item !== null

  useEffect(() => {
    if (item === null) return
    setRegimen(item.regimen)
    setAños(item.Años)
    setMeses(item.Meses)
    setDias(item.dias)
  }, [])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (regimen === 0) {
      showToast({
        title: "Debes ingresar una actividad o régimen",
        message: "Verifica los datos ingresados y vuelve a intentar",
        type: "error",
      })
      return
    }
    if (Años === 0 && Meses === 0 && dias === 0) return
    const newItem: DerechoDirecto = {
      regimen,
      Años,
      Meses,
      dias,
    }
    if (isUpdate) {
      onUpdate(newItem)
    } else {
      onCreate(newItem)
    }
  }

  const onChangeRegimen = (id: number) => setRegimen(id)

  const regimenAportes = (
    <>
      <label
        className="block mb-2 text-sm font-medium text-gray-900"
        htmlFor={"regimen_aportes"}
      >
        Selecciona actividad en la que trabajó
      </label>
      <SelectRegimenAportes
        options={regimenesFromDB}
        sexoSelected={sexoSelected}
        float={true}
        optionSelected={regimen}
        onSelected={onChangeRegimen}
      />
    </>
  )

  return (
    <form action="#">
      <div className="grid gap-4 mb-4 sm:grid-cols-2">
        <div>{regimenAportes}</div>
        <div>
          <label
            htmlFor="Años"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Años
          </label>
          <input
            type="number"
            name={"Años"}
            id={"Años"}
            value={Años}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
            placeholder={"Años"}
            onChange={(e) => setAños(parseInt(e.target.value))}
          />
        </div>
        <div>
          <label
            htmlFor="Meses"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Meses
          </label>
          <input
            type="number"
            min={0}
            max={12}
            name={"Meses"}
            id={"Meses"}
            value={Meses}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
            placeholder={"Meses"}
            onChange={(e) => setMeses(parseInt(e.target.value))}
          />
        </div>
        <div>
          <label
            htmlFor="Días"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Días
          </label>
          <input
            type="number"
            min={0}
            max={31}
            name={"dias"}
            id={"dias"}
            value={dias}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
            placeholder={"Días"}
            onChange={(e) => setDias(parseInt(e.target.value))}
          />
        </div>
      </div>
      <div className="flex items-center justify-end space-x-4">
        <Button
          color={"primary"}
          size={"sm"}
          outline={false}
          onClick={handleSubmit}
        >
          {isUpdate ? "Modificar" : "Agregar"}
        </Button>
      </div>
    </form>
  )
}

export default ModalContentDerechoDirecto
