import React from "react"
import { Outlet, useNavigate } from "react-router-dom"

import { Spinner } from "../../components/common/spinner/Spinner"
import Paths from "../../routes/paths"
import { useGetUserQuery } from "../../services/sessionsApi"
import { getCookie } from "../../utils/cookies"
import { isNullOrEmpty } from "../../utils/validations"
import cover from "./../../assets/images/landing-cover.jpg"
import logoContrast from "./../../assets/images/logo-contrast.png"

const LayoutLogin = () => {
  const navigate = useNavigate()
  const cookie = getCookie("access_token") as string
  const { error, data, isFetching } = useGetUserQuery(cookie)

  if (isNullOrEmpty(error) && !isNullOrEmpty(data)) {
    setTimeout(() => {
      navigate(Paths.account)
    }, 10)
  }

  return (
    <div className="min-h-screen flex flex-col lg:flex-row relative">
      <div className="flex-1 flex overflow-auto px-10 lg:px-20">
        <a href={Paths.home} className="p-10 absolute top-0 left-0">
          <img src={logoContrast} alt="Logo" className="w-auto h-8 mr-2" />
        </a>
        {isFetching && <Spinner align="center"></Spinner>}
        {!isFetching && <Outlet />}
      </div>
      <div className="hidden lg:block bg-yellow-50 w-full lg:max-w-lg xl:max-w-screen-sm flex bg-cover bg-bottom">
        <img className="h-full" src={cover} alt="cover" />
      </div>
    </div>
  )
}

export default LayoutLogin
